
import { Component, Mixins } from 'vue-property-decorator';
import * as Routes from '../../../types/constants/web_client_user.routes';
import { mdiCheck, mdiPlay } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import TOSCheckboxSelfAssessment from '../forms/TOSCheckboxSelfAssessment.vue';
import EmbeddedYoutubeVideo from '../ui/EmbeddedYoutubeVideo.vue';

@Component({
	components: {
		TOSCheckboxSelfAssessment,
		EmbeddedYoutubeVideo,
	}
})
export default class AthleteSelfAssessmentIntro extends Mixins(VuetifyMixin){
	mdiCheck = mdiCheck;
	mdiPlay = mdiPlay;
	releaseAgreed = false;
	beginTest(): void{
		this.$router.push({
			name: Routes.AthleteSelfAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}
}
