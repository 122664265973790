import { AnalyticsAssessmentFilter } from '../../../../libs/ba-types/src';
import { capitalize } from './capitalize.pipe';
export function getCategoryText(value: AnalyticsAssessmentFilter['category'], capitalizeString: boolean = false): string{
	if(value === "age") return capitalizeString ? capitalize("age") : "age";
	if(value === "age_group") return capitalizeString ? capitalize("age group") : "age group";
	if(value === "gender") return capitalizeString ? capitalize("gender") : "gender";
	if(value === "organization") return capitalizeString ? capitalize("organization") : "organization";
	if(value === "position") return capitalizeString ? capitalize("position") : "position";
	if(value === "team") return capitalizeString ? capitalize("team") : "team";
	return "";
}
