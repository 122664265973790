import { DomainObject } from "@/models/DomainObject";
import { FavouriteListModel } from "@/models/favouriteList/FavouriteListModel";
import { CrudApi } from "./CrudApi";

export class FavouriteListApi<T extends DomainObject = DomainObject> extends CrudApi<FavouriteListModel<T>> {
	constructor(protected apiPrefix: string) {
		/** e.g athlete/:athleteId */
		super(`${apiPrefix}/favouriteList`, (obj) =>
			new FavouriteListModel<T>().load(obj)
		);
	}

	async lookUpFavouriteList(): Promise<FavouriteListModel<T> | null> {
		try {
			const obj = await CrudApi.Api((api) =>
				api.get(`${this.resource}`)
			);
			return this.create(obj);
		} catch (e) {
			if (e.response.status === 404) {
				return null;
			}
			throw e;
		}
	}
}
