
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { mdiMagnify, mdiHeart, mdiClose, mdiFormatListBulleted } from "@mdi/js";
import DisplaySchools from "../school/DisplaySchools.vue";
import SchoolModule from "@/store/school/School.store";
import { getModule } from "vuex-module-decorators";
import { RepositoryQuery, QueryOptions } from "types/interfaces";
import { DebounceMixin, LocalForageMixin } from "@/mixins";
import { ESSchoolModel } from "@/models/school/SchoolModel";
import { DataOptions } from "vuetify";
import ButtonToggle from "../../ButtonToggle.vue";
import { userStore } from "@/store";
import FavouriteListProvider from "@/components/hoc/FavouriteListProvider.vue";
import CheckboxFilter from "@/components/search/school/Filters/CheckboxFilter.vue";
import { DivisionFilters, RegionsFilters, AcademicSelectivityFilters, UndergraduateEnrollmentFilters, FilterValue } from "./Filters/Filters";

const schoolStore = getModule(SchoolModule);

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [false],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false
};

@Component({
	components: { DisplaySchools, ButtonToggle, FavouriteListProvider, CheckboxFilter }
})
export default class FilterSchoolSearch extends Mixins(DebounceMixin, VuetifyMixin, LocalForageMixin) {
	@Prop({ default: false }) enabled: boolean; 

	mdiMagnify = mdiMagnify;
	mdiHeart = mdiHeart;
	mdiClose = mdiClose;
	mdiFormatListBulleted = mdiFormatListBulleted;
	schoolSearch: string = "";
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	lengthOfFavouritedSchools: number = 0;
	showFavouriteNumberResult: boolean = false;
	SATCheckClicked: boolean = false;
	satScore: Array<number> = [400, 1600];

	DivisionFilters = DivisionFilters;
	divisionsChecked: string[] = []

	RegionsFilters = RegionsFilters;
	regionsChecked: FilterValue[] = [];

	AcademicSelectivityFilters = AcademicSelectivityFilters;
	academicSelectivityChecked: number[][] = [];

	UndergraduateEnrollmentFilters = UndergraduateEnrollmentFilters;
	undergraduateEnrollmentChecked: Array<number[]> = []

	@Watch('divisionsChecked') async divisionChanged(): Promise<void> {
		this.updateTable();
	}
	@Watch('regionsChecked') async regionsChanged(): Promise<void> {
		this.updateTable();
	}
	
	get CurrentAthleteId(): string {
		return userStore.athleteId;
	}

	updateSearch() {
		this.tableOptions.page = 1
		this.debounceUpdateTable();
	}

	numOfFavouritedSchools(length: number): void {
		this.lengthOfFavouritedSchools = length;
		this.showFavouriteNumberResult = true;

		this.regionsChecked = []
		this.divisionsChecked = [];
		this.academicSelectivityChecked = [];
		this.undergraduateEnrollmentChecked = []
		this.schoolSearch = "";
		this.satScore = [400, 1600];
		this.SATCheckClicked = false;
	}
	clearChecked(): void {
		this.regionsChecked = [];
		this.divisionsChecked = [];
		this.academicSelectivityChecked = [];
		this.undergraduateEnrollmentChecked = []
		this.satScore = [400, 1600]
		this.schoolSearch = "";
		this.SATCheckClicked = false;
		this.clearPersistedFields();
		this.loadSchools();
	}

	mounted(): void {		
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void {
		this.tableUpdatePending = true;
		this.showFavouriteNumberResult = false;
		this.debounceCallback(
			"updateTable",
			async () => {
				try {
					this.tableUpdatePending = false;
					await this.updateTable();
				} catch (e) {
					console.error("Failed to update table");
				} finally {
					this.tableUpdatePending = false;
				}
			},
			400
		);
	}
	localForagePersistFields: Array<string | [string, any]> = [
		['schoolSearch', ''],
		['divisionsChecked', []],
		['regionsChecked', []],
		['academicSelectivityChecked', []],
		['undergraduateEnrollmentChecked', []],
		['satScore', [400, 1600]],
		['SATCheckClicked', false]
	];
	async updateTable(): Promise<void> {
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadSchools();
	}

	get Loading(): boolean{
		return !schoolStore.schoolsInitialized
	}

	get TotalPages(): number {
		return Math.ceil(schoolStore.totalSchools / this.tableOptions.itemsPerPage);
	}

	get TotalFavouritePages(): number {
		return Math.ceil(this.lengthOfFavouritedSchools / this.tableOptions.itemsPerPage);
	}

	async loadSchools(): Promise<void> {
		try {
			const query: RepositoryQuery<ESSchoolModel> = {
				$and: []
			};
			
			// Filter for only soccer
			// query.$and.push({
			// 	$nestedPath: "sports",
			// 	['sports.name' as keyof SchoolModel]: {
			// 		'$any': [SportName.Soccer]
			// 	}
			// })

			if (this.schoolSearch !== null && this.schoolSearch.length > 0) {
				query.$and.push({
					$or: [
						{
							$nestedPath: 'locations',
							$search: {
								query: `${this.schoolSearch}`,
								fields: ['locations.nameSearch'],
								type: "phrase",
								boost: 10,
							}
						},
						{
							$nestedPath: 'locations',
							$search: {
								query: `${this.schoolSearch}`,
								fields: ['locations.nameSearch'],
								type: "phrase_prefix",
								boost: 3,
							}
						},
						{
							$nestedPath: 'locations',
							$search: {
								query: `*${this.schoolSearch}*`,
								fields: ['locations.nameSearch'],
								type: "best_fields",
								boost: 2,
							}
						},
						// {
						// 	$search: {
						// 		query: `*${this.schoolSearch}*`,
						// 		fields: ['locations.citySearch', 'locations.stateSearch', 'athleteDivisionSearch','athleteConferenceSearch'],
						// 		type: "best_fields",
						// 	}
						// }
					]
				}
				)
			}

			if (this.divisionsChecked !== null  && this.divisionsChecked.length > 0) {
				query.$and.push({
					$nestedPath: 'locations.sports',
					['locations.sports.division' as any]: {
						$any: this.divisionsChecked
					}
				})
			}

			if (this.regionsChecked !== null && this.regionsChecked.length > 0) {
				query.$and.push({
					$nestedPath: 'locations',
					$or: this.regionsChecked.map(region => region.value),
				})
			}

			if (this.academicSelectivityChecked !== null && this.academicSelectivityChecked.length > 0){
				query.$and.push({
					acceptanceRate: {
						$range: {
							gte: this.academicSelectivityChecked[0][0],
							lte: this.academicSelectivityChecked[0][1]
						}
					}
				})
			}

			if (this.undergraduateEnrollmentChecked !== null && this.undergraduateEnrollmentChecked.length > 0) {
				query.$and.push({
					undergradsEnrolled: {
						$range: {
							gte: this.undergraduateEnrollmentChecked[0][0],
							lte: this.undergraduateEnrollmentChecked[0][1]
						}
					}
				})
			}

			// SAT Score filter
			query.$and.push(
				{
					["satCompositeScore.min" as any]: {
						$range: {
							gte: this.SATCheckClicked === false ? this.satScore[0]: 0
						}
					}
				},
				{
					["satCompositeScore.max" as any]: {
						$range: {
							lte: this.SATCheckClicked === false ? this.satScore[1]: 1600
						}
					}
				}
			);

			const options: QueryOptions = {
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage
			};
			if (this.tableOptions.sortBy.length > 0) {
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index]
						};
					})
				};
			}
			await schoolStore.loadSchoolList({ query, options });
		} catch (e) {
			console.error(e);
		}
	}

	get TotalSchools(): number {
		return schoolStore.totalSchools;
	}

	get SelectedDivisions(): {text: string, value: string }[] {
		return this.divisionsChecked !== null ? 
			this.DivisionFilters.filter(({ value }) => this.divisionsChecked.findIndex(range => value === range) >= 0) :
			[]
	}
	get SelectedAcademicSelectivity(): {text: string, value: number[] }[] {
		return this.academicSelectivityChecked !== null ? 
			this.AcademicSelectivityFilters.filter(({ value }) => this.academicSelectivityChecked.findIndex(range => value === range) >= 0) :
			[]
	}
	get SelectedUndergradEnrollment(): {text: string, value: number[] }[] {
		return this.undergraduateEnrollmentChecked !== null ? 
			this.UndergraduateEnrollmentFilters.filter(({ value }) => this.undergraduateEnrollmentChecked.findIndex(range => value === range) >= 0) :
			[]
	}

	async removeCheckedDivision(division: string): Promise<void> {
		this.divisionsChecked = this.divisionsChecked.filter(div => division !== div)
		this.debounceUpdateTable()
	}

	async resetSATScore(): Promise<void> {
		this.satScore = [400, 1600];
		this.debounceUpdateTable();
	}

	async resetUndergraduatesEnrolled(): Promise<void> {
		this.undergraduateEnrollmentChecked = [];
		this.debounceUpdateTable();
	}

	async resetAcademicSelectivity(): Promise<void> {
		this.academicSelectivityChecked = [];
		this.debounceUpdateTable();
	}
}
