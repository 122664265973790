
import { Preview } from 'vue-advanced-cropper';
import * as Routes from '../../types/constants/web_client_user.routes';
import { Component, Mixins } from 'vue-property-decorator';
import { mdiEmail, mdiMessageTextOutline, mdiPhone, mdiPencil, mdiPlus, mdiSpeedometer, mdiCalendarCheckOutline, mdiMagnify, mdiClose } from '@mdi/js';
import { AthleteMetric, AthleteMetricValues } from '../../types/enums';
import Page from './Page.vue';
import AthleteVerifiedAssessmentsCard from '../components/profile/athlete/AthleteVerifiedAssessmentsCard.vue';
import AthleteSelfAssessmentSummary from '../components/profile/athlete/AthleteSelfAssessmentSummary.vue';
import ComingSoonCard from '../components/ui/ComingSoonCard.vue'
import EditOverlay from '../components/ui/EditOverlay.vue'
import TeamMiniCard from '../components/ui/TeamMiniCard.vue'
import JerseySvg from '../components/svg/JerseySvg.vue'
import ImageUploadDialog from '../components/profile/ImageUploadDialog.vue'
import StatsSummaryCard from '../components/profile/athlete/StatsSummaryCard.vue'
import { VuetifyMixin, AuthMixin, MyTeamsMixin, MyAthleteMixin, FeatureFlagMixin } from '../mixins';
import { RegisterForAssessmentMixin } from '@/mixins/RegisterForAssessmentMixin';
import { athleteProfile, imagesStore } from '../store';
import RadarChartSvg from '../components/svg/RadarChartSvg.vue'
import SPQSvg from '../components/svg/SPQSvg.vue'
import TacticalClipboardSvg from '../components/svg/TacticalClipboardSvg.vue'
import SurveySvg from '../components/svg/SurveySvg.vue'
import GraduateCapSvg from '../components/svg/GraduateCapSvg.vue'
import { formatDatePretty } from '../helpers';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';
import AthleteSpqReportCard from '../components/profile/athlete/AthleteSpqReportCard.vue';
import { SpqDataModel } from '../models/athlete/SpqDataModel';
import { SelfAssessmentModel } from '../models/athlete/SelfAssessmentModel';
import { Route } from 'vue-router';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import { logGAEvent } from '@/plugins/firebase';
import { UserSubscriptions } from 'types/interfaces';
import { userApi } from '@/api/UserApi';
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";
import AthleteComparativeDataCard from "@/components/profile/athlete/AthleteComparativeDataCard.vue";
import AthleteRyzerReport from "@/components/profile/athlete/AthleteRyzerReport.vue"

@Component({
	components:{
		AthleteVerifiedAssessmentsCard,
		AthleteSelfAssessmentSummary,
		AthleteSpqReportCard,
		ComingSoonCard,
		EditOverlay,
		ProfilePictureUpload,
		Preview,
		StatsSummaryCard,
		ImageUploadDialog,
		Page,
		JerseySvg,
		RadarChartSvg,
		SPQSvg,
		TacticalClipboardSvg,
		SurveySvg,
		GraduateCapSvg,
		TeamMiniCard,
		SubscriptionInfoProvider,
		AthleteScoutingReportsCard,
		AthleteComparativeDataCard,
		AthleteRyzerReport,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteProfile) => {
			if(vm.MyAthleteId !== null){
				athleteProfile.getAthleteProfile(vm.MyAthleteId);
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.MyAthleteId !== null){
			athleteProfile.getAthleteProfile(this.MyAthleteId);
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
		}
		next();
	},
})
export default class AthleteProfile extends Mixins(AuthMixin, MyTeamsMixin, MyAthleteMixin, VuetifyMixin, FeatureFlagMixin, RegisterForAssessmentMixin){
	mdiSpeedometer = mdiSpeedometer;
	mdiEmail = mdiEmail;
	mdiMessageTextOutline = mdiMessageTextOutline;
	mdiPhone = mdiPhone;
	mdiPencil = mdiPencil;
	mdiPlus = mdiPlus;
	mdiMagnify = mdiMagnify;
	mdiCalendarCheckOutline = mdiCalendarCheckOutline;
	mdiClose = mdiClose;

	get UseComparativeDataV2(): boolean{
		return this.feature<number>('DIS-84-comparative-data-page') == 2;
	}

	mounted(): void{
		// Scroll to SPQ Report Card after page loads
		if(this.$route.query.spqData !== undefined){
			setTimeout(() => {
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				this.$vuetify.goTo('#spq-data').catch(() => {});
			}, 500);
		}
	}

	gotoAssessments(): void{
		window.open("https://info.speedtraining.ca/batestingday", "_blank");
	}

	goToComparativeDataV2(): void{
		this.$router.push({
			name: Routes.AthleteComparativeDataCompare,
			params:{
				...this.$route.params,
			}
		});
	}
	addAssessment(): void{
		this.$router.push({
			name: Routes.AthleteClaimAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}
	seePastAssessments(): void{
		this.$router.push({
			name: Routes.AthleteVerifiedAssessmentSummary,
			params:{
				...this.$route.params,
			}
		});
	}

	openAthleteProfileSettings(): void{
		this.$router.push({
			name: Routes.AthleteSettingsAccessControlForm,
			params:{
				...this.$route.params
			}
		})
	}

	openAthleteSubscriptionSettings(): void{
		this.$router.push({
			name: Routes.AthleteSettingsSubscriptionForm,
			params:{
				...this.$route.params
			}
		})
	}

	showImageUploadDialog: boolean = false;

	showImageUpload(): void{
		this.showImageUploadDialog = true;
	}

	get LargeScreen(): boolean{
		return this.$vuetify.breakpoint.lgAndUp;
	}

	expandMetric(metric: string, featureAvailable: boolean = false): void{
		if(this.AssessmentData === null) return;
		this.$router.push({
			name: Routes.AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.MyAthleteId,
				featureAvailable: featureAvailable as any
			}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		}).catch(() => {});
		this.$vuetify.goTo(0);
	}

	get AvatarSize(): {
		size: number,
		borderSize: number,
		}{
		if(this.IsMobile){
			return {
				size: 75,
				borderSize: 3,
			};
		}
		return {
			size: 150,
			borderSize: 6,
		};
	}
	get HasDob(): boolean{
		return !!this.AthleteProfile.birthDate;
	}
	get DateOfBirth(): string{
		if(this.HasDob){
			return formatDatePretty(new Date(this.AthleteProfile.birthDate));
		}
		return '';
	}

	async setProfilePhoto(pictureUrl: string): Promise<void>{
		imagesStore.setCache({id: this.AthleteProfile.id, url: pictureUrl});
		await athleteProfile.setAthleteProfilePicture({ athleteId: this.MyAthleteId, pictureUrl: pictureUrl});
	}

	get AthletePosition(): string{
		if(this.AthleteProfile.sports[0]){
			return this.AthleteProfile.sports[0].primaryPosition;
		}
		return "";
	}

	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get AssessmentData(): AthleteAssessmentDataModel | null{
		return athleteProfile.athleteAssessmentData;
	}
	get SelfAssessmentData(): SelfAssessmentModel | null{
		return athleteProfile.selfAssessmentSummary;
	}

	get SpqData(): SpqDataModel {
		return athleteProfile.athleteSpqData;
	}

	get FullName(): string{
		return `${this.AthleteProfile.firstName} ${this.AthleteProfile.lastName}`;
	}

	get IsMyAthleteProfile(): boolean{
		return this.AthleteProfile.id === this.$auth.athleteId;
	}

	get isPageLoading(): boolean{
		return !athleteProfile.AthleteProfileIsReady;
	}

	get AthleteProfileIsReady(): boolean{
		return athleteProfile.AthleteProfileIsReady;
	}
	get AthleteProfile(): AthleteProfileModel{
		return athleteProfile.athleteProfile;
	}
	get HasVerifiedAssessment(): boolean{
		return this.AthleteProfileIsReady && this.AssessmentData !== null;
	}

	viewTeam(teamId: string): void{
		this.$router.push({
			name: Routes.AthleteTeamDashboard,
			params: {
				teamId
			}
		});
	}

	get JoinTeamRoute(): Partial<Route>{
		if(this.MyAthleteId !== null){
			return {
				name: Routes.AthleteTeamJoin,
				params:{
					athleteId: this.MyAthleteId,
				}
			}
		}
		return {
			name: Routes.TeamJoin,
		};
	}

	joinTeam(): void{
		this.$router.push(this.JoinTeamRoute);
	}
	get BrowseSchoolsRoute(): Partial<Route>{
		return {
			name: Routes.AthleteSchoolSearch,
			params:{
				athleteId: this.MyAthleteId,
			}
		}
	}
	browseSchools(): void{
		this.$router.push(this.BrowseSchoolsRoute);
	}


	get ShowUpgradeNowBanner(): boolean{
		if(this.userSubscriptions === null){
			this.loadUserSubscriptions();
		}else if(this.UserPaidSubscriptions.length === 0){
			return true;
		}
		return false;
	}
	get UserPaidSubscriptions(): SubscriptionModel[] | null{
		if(this.userSubscriptions === null) return null;
		return this.userSubscriptions.athleteProfiles.subscriptions.filter(sub => !sub.plan.free);
	}

	goToUpgrade(): void{
		logGAEvent("upgrade_now_clicked");
		this.$router.push('/upgrade').catch(() => {});
	}

	userSubscriptions: UserSubscriptions<SubscriptionModel> | null = null;
	async loadUserSubscriptions(): Promise<void>{
		this.userSubscriptions = await userApi.getUserSubscriptions();
	}
}
