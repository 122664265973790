
import { Component, Prop, Vue } from 'vue-property-decorator';
import AthleteTargetedSharingModule from '@/store/targetedSharing/AthleteTargetedSharing.store';
import { getModule } from 'vuex-module-decorators';
import { RepositoryQuery, QueryOptions } from 'types/interfaces';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
const athleteTargetedSharingStore = getModule(AthleteTargetedSharingModule);

export type loadSharingDataFn = (opts?: {query?: RepositoryQuery<TargetedSharingModel>,options?: QueryOptions}) => Promise<void>;

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class SharingInfoProvider extends Vue{
	@Prop({ default: 'div' }) as: string;
	@Prop({ type: String, default: null }) sharingId: string | null;

	async created(): Promise<void> {
		await this.initProfile();
	}
	async loadSharingData({
		query = {},
		options = {
			limitPerPage: 5,
			sort:{
				fields: [
					{
						field: 'date',
						desc: true,
					}
				]
			}
		},
	}: {
		query?: RepositoryQuery<TargetedSharingModel>,
		options?: QueryOptions
	} = {}): Promise<void> {
		await athleteTargetedSharingStore.querySharingData({
			athleteId: this.sharingId,
			query,
			options,
		});
	}
	loading: boolean = false;
	ready: boolean = false;
	error: boolean = false;
	async initProfile():Promise<void> {
		try{
			this.loading = true;
			this.ready = false;
			if(
				athleteTargetedSharingStore.querySharingDataAthleteId !== this.sharingId ||
				(athleteTargetedSharingStore.querySharingDataInitialized === false && 
				athleteTargetedSharingStore.querySharingDataLoading === false)
			){
				await this.loadSharingData();
			}
		}catch(error){
			console.error("Failed to load athlete profile", error);
			this.error = true;
		}finally{
			this.ready = true;
			this.loading = false;
		}
	}
	async shareProfile({emails,message}: { emails: string[], message: string }): Promise<void>{
		await athleteTargetedSharingStore.shareProfile({
			athleteId: this.sharingId,
			emails, message
		});
		await this.loadSharingData();
	}
	get SlotProps(): Record<string,any> {
		return {
			Loading: this.loading,
			Ready: this.ready,
			Error: this.error,
			Shares: athleteTargetedSharingStore.totalSharingRecords,
			ShareRecords: athleteTargetedSharingStore.sharingRecords,
			Views: athleteTargetedSharingStore.totalViewedRecords,
			ViewRecords: athleteTargetedSharingStore.viewedRecords,
			Unseen: athleteTargetedSharingStore.totalUnseenRecords,
			UnseenRecords: athleteTargetedSharingStore.unseenRecords,

			loadSharingData: this.loadSharingData.bind(this),
			shareProfile: this.shareProfile.bind(this),
		}
	}
}
