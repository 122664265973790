
import { Component, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins";

import * as Routes from '@/../types/constants/web_client_user.routes';
import { getFlagValue } from '@/feature-flags';

@Component({})
export default class EditRecruitingProfileVideos extends Mixins(VuetifyMixin) {
	get Enabled(): boolean{
		return getFlagValue('athlete-video-studio');
	}
	goToVideoStudio(): void{
		this.$router.push({
			name: Routes.AthleteVideoStudio,
			params: {
				...this.$route.params,
			}
		});
	}
}
