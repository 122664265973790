
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class CodeInput extends Mixins(VuetifyMixin){
	@Prop({ type: Boolean, default: false }) private capitalize: boolean;
	@Prop() private value: string;
	private valueFmt: string = "";
	private input(val: string){
		let value = val;
		if(this.capitalize){
			value = val.toUpperCase();
		}
		this.valueFmt = value;
		this.value = this.cleanValue(value);
		this.$emit('input', this.value);
	}
	private cleanValue(val: string){
		return val.replace(/[^a-zA-Z0-9]+/g, '');
	}
}
