import { render, staticRenderFns } from "./AssociateDiagram.vue?vue&type=template&id=1d70fe58&scoped=true&"
import script from "./AssociateDiagram.vue?vue&type=script&lang=ts&"
export * from "./AssociateDiagram.vue?vue&type=script&lang=ts&"
import style0 from "./AssociateDiagram.vue?vue&type=style&index=0&id=1d70fe58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d70fe58",
  null
  
)

export default component.exports