
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import IdCrossSvg from "@/components/svg/IdCrossSvg.vue";

@Component({
	components: {
		IdCrossSvg,
	}
})
export default class SettingsAlertCard extends Mixins(VuetifyMixin) {
	@Prop({default: "Setting Not Available"}) text: string;
}
