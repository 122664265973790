
import { Component, Mixins, Prop } from 'vue-property-decorator';
// @ts-ignore
import aiImg from '../../../assets/img/ai_1.png';
import { VuetifyMixin } from '../../../mixins';

@Component
export default class MetricChart extends Mixins(VuetifyMixin){
	aiImg = aiImg;
	@Prop() private athleteId: string;
	@Prop() private metric: string;
}
