
import { Component, Prop, Vue } from "vue-property-decorator";
import { FavouriteListApi } from "@/api/favouriteListApi";
import { FavouriteListModel } from "@/models/favouriteList/FavouriteListModel";
import { HinderResource } from '@/../types/constants/resources';
import { DomainObject } from '@/models/DomainObject';

@Component({
	render(h) {
		return h(
			this.as,
			this.$slots.default
				? this.$slots.default
				: this.$scopedSlots.default(this.SlotProps)
		);
	}
})
/**
* this will only work with one favourite List 
*/
export default class FavouriteListProvider<T extends DomainObject = DomainObject > extends Vue {
	@Prop({ default: "div" }) private as: string;
	@Prop({ default: "/" }) private apiPrefix: string;
	@Prop() private parentId: string;
	@Prop({ required: true }) resource: HinderResource;
	@Prop({ required: true }) itemType: T;
	loading: boolean = true;
	favouriteList: FavouriteListModel<T> | null = null;

	get FavouriteListApi(): FavouriteListApi<T> {
		return new FavouriteListApi<T>(`${this.apiPrefix}/${this.parentId}`);
	}

	async lookupFavourites(): Promise<void> {
		this.favouriteList = await this.FavouriteListApi.lookUpFavouriteList();
	}

	async toggleFavourite(item: T): Promise<void> {
		if (this.favouriteList === null){
			this.favouriteList = new FavouriteListModel<T>().load({parentId: this.parentId, resource: this.resource, list:[]})
		} 
		if (this.favouriteList.isInSet(item)){
			this.favouriteList.removeFromSet(item)
		} else {
			this.favouriteList.addToSet(item)
		}
		this.favouriteList = await this.FavouriteListApi.save(this.favouriteList);
	}
	isFavourite(item: T ): boolean {
		if (this.favouriteList !== null){
			return this.favouriteList.isInSet(item)
		}
		return false
	}

	async created(): Promise<void> {
		this.loading = true
		await this.lookupFavourites();
		this.loading = false;
	}

	get SlotProps(): {
		loading: boolean;
		favouriteList: FavouriteListModel<T>;
		isFavourite: (id: string) => boolean;
		toggleFavourite: (item: T) => Promise<void>;
		} 
	{
		return {
			loading: this.loading,
			favouriteList: this.favouriteList,
			isFavourite: this.isFavourite.bind(this),
			toggleFavourite: async (item: T) =>
				this.toggleFavourite(item),
		};
	}
}
