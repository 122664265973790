
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mdiHeartOutline, mdiHeart, mdiSchool } from "@mdi/js";
import { Route } from "vue-router";
import { userStore } from "@/store";
import * as Routes from "@/../types/constants/web_client_user.routes";
import { SchoolFavouriteItem } from "@/models/favouriteList/SchoolFavoriteListModel";
import { ESSchoolModel } from '@/models/school/SchoolModel';
import { VuetifyMixin } from '@/mixins';

@Component({
	components: {}
})
export default class SchoolCard extends Mixins(VuetifyMixin) {
	@Prop() school: ESSchoolModel;
	@Prop() showCard: boolean = true;
	@Prop() isFavourite: boolean;
	mdiHeart = mdiHeart;
	mdiHeartOutline = mdiHeartOutline;
	mdiSchool = mdiSchool;

	get SchoolFavItem(): SchoolFavouriteItem {
		return new SchoolFavouriteItem().load({id: this.school.id, locationId: this.school.locations.id})
	}

	clickFavourite(favoriteItem: SchoolFavouriteItem): void {
		this.$emit("clicked:favourite", favoriteItem);
	}

	DetailPageRoute(id: string): Partial<Route> {
		return {
			name: Routes.SchoolDetails,
			params: {
				athleteId: userStore.athleteId,
				schoolId: id,
				locationId: this.school.locations.id
			}
		};
	}
}
