
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { notificationStore } from '@/store';
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { PaginatedResponse, RepositoryQuery, QueryOptions, ScoutingReportEvaluationAverage, ScoutingReportEvaluationMatrix } from '@/../types/interfaces';
import { PageState } from '@/models/PageState';
import { FeatureFlagMixin } from '@/mixins';
import { formatDate } from '@/helpers';

export type LoadScoutingDataParams = {query?: RepositoryQuery<ScoutingReportModel>, options?: QueryOptions};
export type loadScoutingDataFn = (o: LoadScoutingDataParams) => Promise<void>
export type ScoutingReportEvaluationAverageRadarGroupType = { labels: string[], values: number[]}
export interface ScoutingReportEvaluationAverageRadarChartValuesType {
	FourCorners: ScoutingReportEvaluationAverageRadarGroupType,
	SixMoments: ScoutingReportEvaluationAverageRadarGroupType,
	CRISP: ScoutingReportEvaluationAverageRadarGroupType
}
const chartGroups: (keyof ScoutingReportEvaluationAverageRadarChartValuesType)[] = ['FourCorners', 'SixMoments', 'CRISP']
const chartKeysAndGroup: (keyof ScoutingReportEvaluationMatrix)[][] = [
	['technical', 'tactical', 'physical', 'mental'],
	['attackingOrganization', 'attackingTransition', 'attackingSetPlays', 'defendingOrganization', 'defendingTransition', 'defendingSetPlays'],
	['competitive', 'resilience', 'intelligence', 'speed', 'presence'],
]
const chartLabelAndGroup: string[][] = [
	['Technical', 'Tactical', 'Physical', 'Mental'],
	['Attacking Organization', 'Attacking Transition', 'Attacking Set Plays', 'Defending Organization', 'Defending Transition', 'Defending Set Plays'],
	['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence']
]
@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class AthleteScoutingReportsInfoProvider extends Mixins(FeatureFlagMixin){
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) athleteId: string;

	formatDate = formatDate
	
	async created(): Promise<void> {
		await this.initReports();
	}
	async loadScoutingData(opts:LoadScoutingDataParams = {}): Promise<void> {
		this.scoutingReportsPaginationParams = opts;
		const {query={},options={}} = opts;
		this.scoutingReportsPagination = await scoutingReportApi.queryByAthleteId(
			this.athleteId,
			query,
			options
		);
	}
	async loadEvaluationAverageData(): Promise<void>{
		this.scoutingReportEvaluationAverage = await scoutingReportApi.queryEvaluationAverageByAthleteId(this.athleteId);
	}

	state: PageState = new PageState("Initial");

	scoutingReportsPaginationParams: LoadScoutingDataParams = {};
	scoutingReportsPagination: PaginatedResponse<ScoutingReportModel> | null = null;
	scoutingReportEvaluationAverage: ScoutingReportEvaluationAverage | null = null;

	ScoutingReportsTableData(): any[] {

		var table = [];
		for( var report of this.ScoutingReports)
		{
			const evaluation = report.evaluationMatrix;
			const fourCornerScore = (evaluation.technical + evaluation.tactical + evaluation.physical + evaluation.mental) / 4.00;
			const sixMomentScore = (evaluation.attackingOrganization + evaluation.attackingTransition + evaluation.attackingSetPlays +
			evaluation.defendingOrganization + evaluation.defendingTransition + evaluation.defendingSetPlays) / 6.00;
			const crispScore = (evaluation.competitive + evaluation.resilience + evaluation.intelligence + evaluation.speed + evaluation.presence) / 5.00;

			const fourCornersData = {
				score: fourCornerScore.toFixed(1),
				labels: chartLabelAndGroup[0], 
				values: [evaluation.technical, evaluation.tactical, evaluation.physical, evaluation.mental]
			}
			const sixMomentsData = {
				score: sixMomentScore.toFixed(1),
				labels: chartLabelAndGroup[1], 
				values: [evaluation.attackingOrganization, evaluation.attackingTransition, evaluation.attackingSetPlays,
						evaluation.defendingOrganization, evaluation.defendingTransition, evaluation.defendingSetPlays]
			}
			const crispData = {
				score: crispScore.toFixed(1),
				labels: chartLabelAndGroup[2], 
				values: [evaluation.competitive, evaluation.resilience, evaluation.intelligence, evaluation.speed, evaluation.presence]
			}

			table.push({
				id: report.id,
				date: formatDate(report.createdAt), 
				location: report.gameDetails.location, 
				performance: evaluation.performanceScore, 
				potential: evaluation.potentialScore,
				corners: fourCornersData,
				moments: sixMomentsData,
				crisp: crispData,
			})
		}

		return table;
	}


	async initReports():Promise<void> {
		try{
			this.state = new PageState("Loading");
			await this.loadScoutingData();
			await this.loadEvaluationAverageData();
			this.state = new PageState("Ready");
		}catch(error){
			notificationStore.pushErrorNotificationMessage("Failed to load Scouting Reports. Please try again later.");
			this.state = PageState.getPageState(error);
		}
	}

	get TotalPages(): number{
		if(this.scoutingReportsPagination === null) return 0;
		return this.scoutingReportsPagination.totalPages;
	}

	get TotalScoutingReports(): number{
		if(this.scoutingReportsPagination === null) return 0;
		return this.scoutingReportsPagination.total;
	}

	get ScoutingReports(): ScoutingReportModel[]{
		if(this.scoutingReportsPagination === null) return [];
		return this.scoutingReportsPagination.docs;
	}
	get ScoutingReportEvaluationAverageRadarChartValues(): ScoutingReportEvaluationAverageRadarChartValuesType | null {
		if (!this.scoutingReportEvaluationAverage) return null;

		const returnObj: ScoutingReportEvaluationAverageRadarChartValuesType = {
			FourCorners: { labels: [], values: []},
			SixMoments: { labels: [], values: []},
			CRISP: { labels: [], values: []},
		}

		const scoutingReportVersion2Enabled = this.feature('HIN-1577-new-scouting-report-fields');		

		for (let i = 0; i < chartKeysAndGroup.length; i++) {
			const group = chartKeysAndGroup[i];
			for (let j = 0; j < group.length; j++) {
				const key = group[j];
				const isVersion2Key = key === 'attackingSetPlays' || key === 'defendingSetPlays';
				
				if (
					this.scoutingReportEvaluationAverage[key] !== undefined && this.scoutingReportEvaluationAverage[key] !== null &&

					/**
					 * Check if it is a version 2 key -> then the feature must also be on to use it
					 */
					((isVersion2Key && scoutingReportVersion2Enabled) || (!isVersion2Key))
				){	
					returnObj[chartGroups[i]].values.push(this.scoutingReportEvaluationAverage[key])
					returnObj[chartGroups[i]].labels.push(chartLabelAndGroup[i][j])
				}
			}
		}

		return returnObj;
	}


	get IsFourMomentsAvailable(): boolean {
		return this.ScoutingReportEvaluationAverageRadarChartValues?.SixMoments?.values.length === 4
	}
	get IsSixMomentsAvailable(): boolean {
		return this.ScoutingReportEvaluationAverageRadarChartValues?.SixMoments?.values.length === 6
	}

	get SlotProps(): Record<string,any> {
		return {
			state: this.state,
			ScoutingReports: this.ScoutingReports,
			EvaluationAverage: this.scoutingReportEvaluationAverage,
			TotalScoutingReports: this.TotalScoutingReports,
			TotalPages: this.TotalPages,
			loadScoutingData: this.loadScoutingData.bind(this),
			ScoutingReportEvaluationAverageRadarChartValues: this.ScoutingReportEvaluationAverageRadarChartValues,
			IsFourMomentsAvailable: this.IsFourMomentsAvailable,
			IsSixMomentsAvailable: this.IsSixMomentsAvailable,
			ScoutingReportsTableData: this.ScoutingReportsTableData(),
		}
	}
}
