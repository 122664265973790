
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {mdiInformationOutline} from '@mdi/js';
import {AuthMixin, VuetifyMixin} from '../../../mixins';
import SpqDataInfoProvider from '../../../components/hoc/SpqDataInfoProvider.vue';
import {SpqDataModel} from '../../../models/athlete/SpqDataModel';
import {formatDatePretty} from '../../../helpers';
import SpqGrid from '../../../components/profile/athlete/SpqGrid.vue';
import {RoleName} from '../../../../types/enums';
import * as Routes from '../../../../types/constants/web_client_user.routes';
import SpqTipsDialog from '../../../components/profile/athlete/SpqTipsDialog.vue';

@Component({
	components: {
		SpqTipsDialog,
		SpqDataInfoProvider,
		SpqGrid
	}
})
export default class AthleteSpqReportCard extends Mixins(VuetifyMixin, AuthMixin) {
	mdiInformationOutline = mdiInformationOutline;

	showTipsDialog = false;
	athlete = true;

	@Prop({ default: null }) spqData: SpqDataModel | null;
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Number, default: '100%' }) private height: string;
	@Prop({ default: true }) addAssessmentBtnVisible: boolean;
	@Prop({ default: null }) athleteId: string;
	@Prop({ default: false}) showTips: boolean;

	get RootStyle(): Record<string,any>{
		return {
			'height': this.height,
			'flex-basis': '300px',
		};
	}

	get HasSpqData(): boolean {
		return this.spqData != null && this.spqData !== undefined;
	}

	get LastSpqDate(): string | undefined {
		if (!this.HasSpqData) return undefined;
		return formatDatePretty(this.spqData.date);
	}

	get Context(): RoleName {
		if (this.athlete === true) return RoleName.Athlete;
		return RoleName.Coach;
	}

	get PictureProps(): Record<string,any> {
		if (this.Context === RoleName.Athlete) {
			return {
				profileId: this.AthleteId,
				athlete: true,
				coach: false,
			};
		}
		return {
			profileId: this.CoachId,
			athlete: false,
			coach: true,
		};
	}

	get CoachId(): string {
		return this.$auth.coachId;
	}

	get AthleteId(): string{
		// Use the athleteId provided otherwise use the athleteId in $auth (auth0)
		return this.athleteId ? this.athleteId : this.$auth.athleteId ;
	}

	addAssessment(){
		this.$router.push({
			name: Routes.AthleteClaimSPQAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}
}
