
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetricsMixin, VuetifyMixin } from '../../mixins';
import { getMetricUnit, getMetricUnitLabel } from '../../pipes';
import AthleteSelfAssessmentMetricDescription from './AthleteSelfAssessmentMetricDescription.vue';
import TestGroupHeader from './TestGroupHeader.vue';
import UserSelfReportSvg from '../svg/UserSelfReportSvg.vue';
import { SelfAssessmentTestModel } from '../../models/athlete/SelfAssessmentTestModel';
import { AthleteMetric } from '../../../types/enums';

@Component({
	components: {
		AthleteSelfAssessmentMetricDescription,
		TestGroupHeader,
		UserSelfReportSvg,
	},
})
export default class AthleteSelfAssessmentFormStep extends Mixins(AthleteMetricsMixin, VuetifyMixin){
	getMetricUnit = getMetricUnit;
	getMetricUnitLabel = getMetricUnitLabel;
	@Prop({ default: false, type: Boolean }) isOpen: boolean;
	@Prop({ default: false, type: Boolean }) useOverlay: boolean;
	@Prop({ default: false, type: Boolean }) hideControls: boolean;
	@Prop({ required: true }) metric: AthleteMetric;
	@Prop({ required: true }) results: SelfAssessmentTestModel;
	private updateResults(results: SelfAssessmentTestModel){
		this.results = results;
		this.$emit('update:results', results);
	}

	showTestDialog: boolean = false;
	private updateDialog(val: boolean){
		this.showTestDialog = val;
		this.$emit('update:show-test-dialog', val);
	}
	private beginTest(){
		this.updateDialog(true);
	}
	private redoTest(){
		this.clearResults();
		this.beginTest();
	}
	private clearResults(){
		this.updateResults(new SelfAssessmentTestModel(this.metric));
	}

	private closeDialog(){
		this.updateDialog(false);
	}

	get HasResults(): boolean{
		if(!this.results) return false;
		return this.results.finalResult !== null;
	}
	get FinalResult(): string{
		if(this.results.finalResultLabel){
			return `${this.results.finalResultLabel}${getMetricUnit(this.metric, { selfAssessment: true })}`;
		}
		return `${this.results.finalResult}${getMetricUnit(this.metric, { selfAssessment: true })}`;
	}

	get UseVelocity(): boolean{
		return this.metric === AthleteMetric.Speed;
	}
}
