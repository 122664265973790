
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, RyzerMixin, CurrentAthleteMixin } from "@/mixins";
import { formatDatePretty } from '../../../helpers';

import RyzerScore from "@/components/ui/RyzerScore.vue";


@Component({
	components: {
		RyzerScore,
	}
})
export default class AthleteRyzerReport extends Mixins(VuetifyMixin, RyzerMixin, CurrentAthleteMixin) {
	@Prop({required:true}) athleteId : string;
	@Prop({default:true}) buttonsVisible : boolean;


	// TODO: read athlete type from database

	get LastAssessedDate(): string | undefined{
		return formatDatePretty(new Date());
	}
}
