
import { Component, Mixins } from 'vue-property-decorator';
import FilterSchoolSearch from '../components/search/school/FilterSchoolSearch.vue';
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import { CurrentAthleteMixin } from '@/mixins';
import FeatureNotAvailableDialog from "@/components/subscriptions/FeatureNotAvailableDialog.vue";
import SchoolSearchSvg from "@/components/svg/SchoolSearchSvg.vue";
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { StartCheckoutParams } from 'types/interfaces';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { CustomerApi } from '@/api/CustomerApi';
import { notificationStore, schoolStore } from '@/store';
import * as Routes from "@/../types/constants/web_client_user.routes";
import SubscriptionsDialog from "@/components/subscriptions/SubscriptionsDialog.vue";

@Component({
	components: {
		FilterSchoolSearch,
		SubscriptionInfoProvider,
		FeatureNotAvailableDialog,
		SchoolSearchSvg,
		SubscriptionsDialog
	}
})
export default class AthleteSchoolSearch extends Mixins(CurrentAthleteMixin){	
	showPlansModal = false;
	getPlanLoading = false;

	get Loading():boolean{
		return schoolStore.schoolsLoading
	}
	
	async getPlan(plan: SubscriptionPlanModel): Promise<void>{
		this.showPlansModal = false;
		this.getPlanLoading = true;
		const params: StartCheckoutParams<AthleteLineItemMeta> = {
			title: `Upgrade to ${plan.title} Plan`,
			parentId: this.CurrentAthleteId,
			products:[
				{
					productId: plan.stripeProductId,
					quantity: 1,
					meta: {
						athleteId: this.CurrentAthleteId,
					},
				}
			],
		};
		try{
			const intent = await CustomerApi.createCheckoutIntent('athlete', this.CurrentAthleteId, params);
			this.$router.push({
				name: Routes.AthleteCheckoutPage,
				params:{
					...this.$route.params,
					checkoutIntentId: intent.id
				}
			});
		}catch(e){
			console.error("Failed to purchase plan", e);
			notificationStore.pushErrorNotificationMessage("Something went wrong, please try again later");
		}finally{
			this.getPlanLoading = false;
		}
	}
}
