
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
@Component({
})
export default class TOSCheckboxSelfAssessment extends Mixins(VuetifyMixin){
	@Prop({ default: false, type: Boolean }) private value: boolean;
	input(value: boolean){
		this.$emit('input', value === true);
	}

	showTos: boolean = false;
	showPrivacy: boolean = false;

	get HeaderStyle(){
		return {
			'background-color': this.getColor('baColorDeepBlue'),
		};
	}
}
