
import { Component, Prop, Mixins } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import IdCrossSvg from "@/components/svg/IdCrossSvg.vue";
import { mdiClose } from '@mdi/js';
import { userStore } from '@/store';
import * as Routes from '../../../types/constants/web_client_user.routes';

@Component({
	components: {
		IdCrossSvg
	}
})
export default class FeatureNotAvailableDialog extends Mixins(VuetifyMixin) {
	@Prop({default: "Feature Not Available"}) text: string;
	mdiClose = mdiClose

	get IsMobile(): boolean{
		return this.$vuetify.breakpoint.smAndDown
	}
	
	AthleteProfileRoute(): void{
		this.$router.push({
			name: Routes.AthleteSchoolSearch,
			params:{
				profileShortId: userStore.athlete.shortId,
				athleteId: userStore.athlete.id,
			}
		});
	}
}
