
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AthleteRoutingMixin, AuthMixin } from "@/mixins";
import { ScoutingReportScoreSelectorMixin } from "@/mixins/selectors/ScoutingReportScoreSelector";
import ViewCoachScoutingReport from '../views/ViewCoachScoutingReport.vue';

@Component({
	components: {ViewCoachScoutingReport },
})

export default class AthleteScoutingReport extends Mixins(AuthMixin, VuetifyMixin, ScoutingReportScoreSelectorMixin, AthleteRoutingMixin){
	@Prop({ default: null, type: String }) reportId: string | null;

}

