
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {AuthMixin, VuetifyMixin} from '../../../mixins';
import LocationPin from '../../../components/charts/LocationPin.vue';

@Component({
	components: {
		LocationPin
	}
})
export default class SpqGrid extends Mixins(VuetifyMixin, AuthMixin) {
	@Prop({ required: true }) private x!: number;
	@Prop({ required: true }) private y!: number;
	@Prop({ default: '' }) private labelTopLeft;
	@Prop({ default: '' }) private labelTopRight;
	@Prop({ default: '' }) private labelBottomLeft;
	@Prop({ default: '' }) private labelBottomRight;
	@Prop({ default: '' }) private pictureUrl;
	@Prop({default: '300px'}) private minHeight?: string;
}
