
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class AssociateDiagram extends Mixins(VuetifyMixin){
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: null }) private leftLabel: string | null;
	@Prop({ default: null }) private rightLabel: string | null;
}
