
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mdiAccount } from '@mdi/js';
import { VuetifyMixin, DebounceMixin } from '../../mixins';
import LocationPin from './LocationPin.vue';
import ProgressBar from './ProgressBar.vue';
import { ComparisonDataItem } from '../../../types/interfaces';
import { performanceCategoryToLabel, performanceCategoryToColor } from '../../pipes';
import { StatisticModel } from '../../models/statistic';
import { PerformanceCategory, AthleteMetric, PerformanceCategoryIndex } from '../../../types/enums';
import { AgeGroup, compareAgeGroup } from '@best-athletes/ba-types';
import { assessmentMetricValueOrNull } from '@/pipes/assessment-metric-value-or-null.pipe';
@Component({
	components: {
		ProgressBar,
		LocationPin,
	}
})
export default class ComparisonBarChart extends Mixins(DebounceMixin, VuetifyMixin){
	mdiAccount = mdiAccount;
	$refs:{
		chartTable: HTMLTableElement;
	}
	mounted(): void{
		this.getTableHeight();
	}
	tableHeight: number = 0;
	/**
	 * Recompute the table height when the data changes so we can draw a line to the bottom.
	 * The height won't be correct until the CSS animations complete, so a delay is necessary/
	 */
	@Watch('data')
	getTableHeight(): void{
		this.debounceCallback('getTableHeight', () => {
			if(this.$refs.chartTable !== undefined){
				this.tableHeight = this.$refs.chartTable.clientHeight;
			}
		}, 500);
	}


	@Prop({ default: null }) primaryAgeGroup: AgeGroup | null;
	@Prop({ type: Boolean, default: false }) over18: boolean;
	@Prop({ type: Boolean, default: false }) useAvatar: boolean;
	@Prop({ type: Number, default: 12 }) barHeight: number;
	@Prop({ type: Number, default: 8 }) altBarHeight: number;
	@Prop({ type: Number, default: null }) compareValue: number | null;
	@Prop({ type: String, default: 'baColorDeepBlue' }) compareColor: string;
	@Prop() ageGroup: AgeGroup;
	@Prop({ type: String, default: "s" }) unit: string;
	@Prop({ type: Boolean, default: false }) useDecimal: boolean;
	@Prop({ type: String }) pictureUrl: string;
	@Prop({ type: String }) metric: AthleteMetric;
	@Prop({ required: true }) data: StatisticModel[];

	get AvatarSize(): number{
		return this.CompareBarHeight * 1.8;
	}
	get AvatarStyle(): Record<string, string>{
		return {
			bottom: `-${this.AvatarSize / 8}px`,
			right: `-${8}px`,
		};
	}

	get ShowCompareBar(): boolean{
		return this.compareValue !== null;
	}
	get UseSideLabel(): boolean{
		return this.$vuetify.breakpoint.smAndUp;
	}
	get UseAvatar(): boolean{
		return this.useAvatar || !this.pictureUrl;
	}

	get ValueLabelFontSize(): string{
		return `${this.barHeight+2}px`;
	}
	get AltValueLabelFontSize(): string{
		return `${this.altBarHeight+2}px`;
	}

	get Min(): number{
		return 0;
	}
	get Max(): number{
		if(this.data.length === 0) return 0;
		const max = this.data
			.map(d => d.getMetricValue(this.metric))
			.reduce((a,b) => {
				return Math.max(a, b);
			},0);
		return max * 1.1;
	}
	/**
	 * 2.2 + s => '2.20s'
	 */
	formatValue(value: number | string): string{
		if(Number.isNaN(value) || value === null || value === undefined || value === assessmentMetricValueOrNull()){
			return 'No Data';
		}
		return `${(+value).toFixed(this.useDecimal ? 2 : 0)}${this.unit}`;
	}

	valueAsPercentage(value: number): number{
		return ~~(((+value)/this.Max)*100);
	}

	get Over18(): boolean{
		return this.over18;
	}

	get AgeGroups(): AgeGroup[]{
		return Array.from(new Set(this.data.map(d => d.ageGroup)));
	}

	get PerformanceCategories(): PerformanceCategory[]{
		if(this.Over18){
			return [
				PerformanceCategory.Recreational,
				PerformanceCategory.Competitive,
				PerformanceCategory.College,
				PerformanceCategory.Professional,
				PerformanceCategory.International,
			];
		}
		return [
			PerformanceCategory.Recreational,
			PerformanceCategory.Competitive,
			PerformanceCategory.HighPerformance,
			PerformanceCategory.ProAcademy,
			PerformanceCategory.International,
		];
	}

	/**
	 * Looks up colors from the theme for each bar. L1,L2,D1,D2 colors added for lighten / darken in 5% increments.
	 */
	findColor(performanceCategory: PerformanceCategory, index: number = 0): string{
		const ColorOffset = Math.min(index,2);
		const color = performanceCategoryToColor(performanceCategory);
		const offsetColor = `${color}${ColorOffset > 0 ? `L${ColorOffset}` : ''}`
		return this.getColor(offsetColor);
	}

	/**
	 * Converts the Statistics array from the data prop to list of ComparisonDataItems (bars on the chart) Grouped by the 5 performance categories.
	 */
	get DataGroups(): { label: string, performanceCategory: PerformanceCategory, data: ComparisonDataItem[] }[]{
		const chartDataGroups = this.PerformanceCategories.map(performanceCategory => {
			return {
				label: performanceCategoryToLabel(performanceCategory),
				performanceCategory: performanceCategory,
				data: [],
			};
		});
		chartDataGroups.forEach(group => {
			group.data.push(
				...this.data
					// Filter all the Statistics down to only the one from each AgeGroup in this Performance Category
					.filter(stat => PerformanceCategoryIndex[stat.performanceCategory] === PerformanceCategoryIndex[group.performanceCategory])
					// Sort the Stats by AgeGroup and force the 'primaryAgeGroup' to the front
					.sort((a: StatisticModel, b: StatisticModel): number => {
						if(a.ageGroup === this.primaryAgeGroup) return -1;
						return compareAgeGroup(a.ageGroup, b.ageGroup);
					})
					// Convert the Stats into ComparisonDataItems which the Chart expects to render them as bars
					.map((stat, index): ComparisonDataItem => ({
						label: stat.ageGroup,
						value: stat.getMetricValue(this.metric),
						color: this.findColor(stat.performanceCategory, index),
						progress: this.valueAsPercentage(stat.getMetricValue(this.metric)),
					}))
			);
		});
		return chartDataGroups;
	}

	get ItemCount(): number{
		return this.data.length;
	}

	get CompareColor(): string{
		return this.getColor(this.compareColor);
	}
	get CompareBarHeight(): number{
		return this.barHeight * 2;
	}
	get ComparePercentage(): number{
		return this.valueAsPercentage(this.compareValue);
	}
	get CompareLabel(): string{
		return this.formatValue(this.compareValue);
	}

	
	get CompareLineHeight(): string{
		return `${this.tableHeight}px`;
	}

}
