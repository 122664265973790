
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronRight } from '@mdi/js';
import { AthleteMetric } from '../../../../types/enums';
import { VuetifyMixin } from '../../../mixins';
import AssessmentDataInfoProvider from '../../hoc/AssessmentDataInfoProvider.vue';
import ExpandingCard from '../../ui/ExpandingCard.vue';

import RulerSvg from '../../svg/RulerSvg.vue';
import SpeedometerSvg from '../../svg/SpeedometerSvg.vue';
import StopwatchSvg from '../../svg/StopwatchSvg.vue';
import LightningSvg from '../../svg/LightningSvg.vue';
import AgilitySvg from '../../svg/AgilitySvg.vue';
import HeartVitalSvg from '../../svg/HeartVitalSvg.vue';
import { SelfAssessmentModel } from '../../../models/athlete/SelfAssessmentModel';


@Component({
	components: {
		AssessmentDataInfoProvider,
		ExpandingCard,

		RulerSvg,
		SpeedometerSvg,
		StopwatchSvg,
		LightningSvg,
		AgilitySvg,
		HeartVitalSvg,
	}
})
export default class AthleteSelfAssessmentStatCard extends Mixins(VuetifyMixin){
	mdiChevronRight = mdiChevronRight;
	@Prop({ required: true }) private metric: AthleteMetric;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ required: true }) private selfAssessment: SelfAssessmentModel;
	@Prop({ type: Boolean, default: false }) private loading: boolean;
	@Prop({ type: Number, default: '100%' }) private height: string;
	@Prop({ type: Boolean, default: true }) loadData: boolean;

	get RootStyle(): Record<string,any>{
		return {
			'height': this.height,
			'flex-basis': '300px',
		};
	}
}
