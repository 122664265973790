import { render, staticRenderFns } from "./SpqGrid.vue?vue&type=template&id=786d0048&scoped=true&"
import script from "./SpqGrid.vue?vue&type=script&lang=ts&"
export * from "./SpqGrid.vue?vue&type=script&lang=ts&"
import style0 from "./SpqGrid.vue?vue&type=style&index=0&id=786d0048&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "786d0048",
  null
  
)

export default component.exports