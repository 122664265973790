import { render, staticRenderFns } from "./ClaimSPQAssessmentForm.vue?vue&type=template&id=6c1d8dfa&"
import script from "./ClaimSPQAssessmentForm.vue?vue&type=script&lang=ts&"
export * from "./ClaimSPQAssessmentForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports