
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { formatDatePretty, formatDate } from '@/helpers';
import { DataTableHeader } from 'vuetify';
import { VuetifyMixin, AthleteRoutingMixin, CurrentAthleteMixin } from '@/mixins';
import AthleteScoutingReportsInfoProvider from '@/components/hoc/AthleteScoutingReportsInfoProvider.vue';
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";

@Component({
	components:{
		AthleteScoutingReportsInfoProvider,
		ScoutingReportSpiderChart,
		ScoutingReportScoreChip,
	}
})
export default class AthleteScoutingReportsView extends Mixins(VuetifyMixin, AthleteRoutingMixin, CurrentAthleteMixin){
	@Prop({ default: null }) athleteId: string | null;

	formatDateHeader = formatDatePretty
	formatDateTable = formatDate

	search = ""
	expanded: []

	scoutingReportHeaders: Array<DataTableHeader> = [
		{ text: 'Date', value: 'date', align: 'center'},
		{ text: 'Location', value: 'location', filterable: true},
		{ text: 'Performance', value: 'performance', align: 'center'},
		{ text: 'Potential', value: 'potential', align: 'center'},
		{ text: '4 Corners', value: 'corners.score', align: 'center'},
		{ text: '6 Moments', value: 'moments.score', align: 'center'},
		{ text: 'C.R.I.S.P', value: 'crisp.score', align: 'center'},
		{ text: '', value: 'data-table-expand'},
	]

	get AthleteId(): string | null {
		return this.CurrentAthleteId ?? this.athleteId;
	}

	// Actions
	onClickRow(item, event) {
		if( this.IsMobile )
		{
			this.goToScoutingReport(item.id);
		}
		else
		{
			event.expand(!event.isExpanded);
		}
	}
}
