
import { Component, Mixins } from "vue-property-decorator";
import { mdiMovieOpen } from "@mdi/js";
import Page from "@/views/Page.vue";
import { VuetifyMixin , VideoUploadMixin, CurrentAthleteMixin, FeatureFlagMixin} from "@/mixins";
import BAVideoStudio from "@/components/videoStudio/BAVideoStudio.vue";
import ClipPlayerStudio from "@/components/videoClipPlayer/ClipPlayerStudio.vue";
import AthleteVideoHighlightReel from '@/components/profile/athlete/AthleteVideoHighlightReel.vue';
import VideoClipLibrary from '@/components/ui/videos/VideoClipLibrary.vue';
import VideoClipPreview from '@/components/ui/videos/VideoClipPreview.vue';
import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { athleteProfile } from "@/store";
import { videoClipApi } from '@/api/VideoClipApi';
import { BehaviorSubject } from 'rxjs';
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import { VideoUploadDialogVariant } from "@/components/videoStudio/VideoUploadDialog.vue";

@Component({
	components: {
		AthleteVideoHighlightReel,
		ClipPlayerStudio,
		Page,
		BAVideoStudio,
		VideoClipPreview,
		VideoClipLibrary,
		VideoLibraryProvider,
		SubscriptionInfoProvider
	}
})
export default class AthleteVideoStudio extends Mixins(CurrentAthleteMixin, VuetifyMixin, VideoUploadMixin, FeatureFlagMixin) {
	mdiMovieOpen = mdiMovieOpen;
	VideoUploadDialogVariant = VideoUploadDialogVariant

	videos: VideoModel[] = [];
	videoClips: VideoClipModel[] = [];
	selectedVideoClipObservable: BehaviorSubject<VideoClipModel | null> = new BehaviorSubject<VideoClipModel | null>(null);

	selectedHighlightClipObservable: BehaviorSubject<VideoClipModel | null> = new BehaviorSubject<VideoClipModel | null>(null);

	videoStudioVisible: boolean = false;
	highlightReelPlayerVisible: boolean = false;
	HighlightReelClips(VideoClips: VideoClipModel[]): VideoClipModel[]{
		return VideoClips.filter(clip => clip.inHighlightReel);
	}
	clipPlayerVisible: boolean = false;

	filteredClips: VideoClipModel[] = [];
	updateFilteredClips(clips: VideoClipModel[]): void{
		this.filteredClips = clips;
	}

	$refs: {
		highlightClipPlayer: ClipPlayerStudio,
		videoClipPlayer: ClipPlayerStudio,
	};
	playAll(): void{
		this.highlightReelPlayerVisible = true;
		this.$refs.highlightClipPlayer.playAll();
	}
	playClip(videoClip: VideoClipModel): void{
		this.clipPlayerVisible = true;
		this.selectedVideoClipObservable.next(videoClip);
	}
	videoClipClicked(videoClip: VideoClipModel): void{
		this.playClip(videoClip);
	}
	playHighlightClip(videoClip: VideoClipModel): void{
		this.highlightReelPlayerVisible = true;
		this.selectedHighlightClipObservable.next(videoClip);
	}
	highlightVideoClipClicked(videoClip: VideoClipModel): void{
		this.playHighlightClip(videoClip);
	}

	async deleteClip(clip: VideoClipModel): Promise<void>{
		await videoClipApi.delete(clip).catch((e) => console.error(e));
		await this.persistClips(this.videoClips.filter(v => v.id !== clip.id));
	}
	
	async persistClips(clips: VideoClipModel[]): Promise<void> {
		await athleteProfile.patchAthleteProfile({ 
			patch: { id: this.CurrentAthleteId, clips: clips }, 
			options: {withAcls: false},
		})
	}

}
