
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../../mixins';
import RulerSvg from '../../svg/RulerSvg.vue';
import ExpandingCard from '../../ui/ExpandingCard.vue';
import { AthleteAssessmentDataModel } from '../../../models/athlete/AthleteAssessmentDataModel';
import { AthleteProfileModel } from '../../../models/athlete/AthleteProfileModel';
import convert from "convert-units";
import { SelfAssessmentModel } from '@/models';
import { toFixed } from '@/pipes';

@Component({
	components: { ExpandingCard, RulerSvg }
})
export default class StatsSummaryCard extends Mixins(VuetifyMixin){

	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ required: true }) private athleteProfile: AthleteProfileModel;
	@Prop({ required: true }) private assessmentData: AthleteAssessmentDataModel;
	@Prop({ default: null }) private selfAssessment: SelfAssessmentModel | null;

	get Height(): string{
		let height: number;

		// Height is always stored in metric(cm)
		if(this.athleteProfile.height)
			height = this.athleteProfile.height
		else if(this.assessmentData)
			height = this.assessmentData.standingHeight;
		else if(this.selfAssessment !== null)
			height = this.selfAssessment.standingHeightCm;

		if (height && height > 0){
			// Height in feet and inches
			const height_ft = Math.floor(convert(height).from('cm').to('ft'));
			const height_in =  Math.floor(convert(height).from('cm').to('in') % 12);

			return `${height_ft}'${height_in}” / ${toFixed(height)} cm`
		}
		else
			return '--'
	}
	get Weight(): string{
		let weight: number;

		// Height will always be in metric(kg)
		if(this.athleteProfile.weight)
			weight = this.athleteProfile.weight
		else if(this.assessmentData)
			weight = this.assessmentData.mass;
		else if(this.selfAssessment !== null)
			weight = this.selfAssessment.bodyMassKg;

		if (weight && weight > 0){
			const weight_lb = Math.ceil(convert(weight).from('kg').to('lb'));
			return `${weight_lb} lbs / ${toFixed(weight)} kg`
		}
		else
			return `--`;
	}
	get DominantSide(): string{
		if(!this.athleteProfile.dominantSide && !this.assessmentData ) return '--';
		if (this.athleteProfile.dominantSide) return this.athleteProfile.dominantSide;
		return '--';
	}
}
