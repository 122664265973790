
import { Component, Mixins } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { FeatureFlagMixin } from '@/mixins';
import AthleteComparativeData from '@/views/AthleteComparativeData.vue';
import AthleteComparativeDataV2 from '@/views/AthleteComparativeDataV2.vue';
import * as Routes from '../../types/constants/web_client_user.routes';
import { RawLocation } from 'vue-router';

@Component({
	components: {
		AthleteComparativeData,
		AthleteComparativeDataV2
	}
})
/**
 * Root view for the Athlete or Coach's TeamDashboard. Chooses which component to be rendered
 */
export default class AthleteComparativeDataView extends Mixins(
	FeatureFlagMixin
) {
	get Component(): VueConstructor {
		switch (this.feature<number>('DIS-84-comparative-data-page')) {
			case 1:
				return AthleteComparativeData;
			case 2:
				return AthleteComparativeDataV2;
			default:
				return AthleteComparativeData;
		}
	}
	created() {
		let newLocation: RawLocation = {
			name: Routes.AthleteComparativeDataSummary,
			params: {
				metric: 'power'
			}
		}
		switch (this.feature<number>('DIS-84-comparative-data-page')) {
			case 1:
				newLocation = {
					name: Routes.AthleteComparativeDataSummary,
					params: {
						metric: 'power'
					}
				};
				break;
			case 2:
				newLocation = {
					name: Routes.AthleteComparativeDataCompare
				};
				break;
			default:
				break;
		}

		if (this.$route.name !== newLocation.name) {
			this.$router.replace(newLocation);
		}
	}
}
