
import {Component, Mixins, Prop} from 'vue-property-decorator';
import {VuetifyMixin} from '../../../mixins';
import Dialog from '../../ui/Dialog.vue';

@Component({
	components: { Dialog }
})
export default class SpqTipsDialog extends Mixins(VuetifyMixin) {
	@Prop({type: Boolean, default: false}) private show: false;
}
