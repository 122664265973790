import { CrudApi } from './CrudApi';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';

export class AthleteTargetedSharingApi extends CrudApi<TargetedSharingModel>{
	constructor(athleteId: string) {
		super(`targetedSharing/athlete/${athleteId}`, (obj) => new TargetedSharingModel().load(obj));
	}
	async shareToEmails({ emails, message }: {emails: string[], message: string}): Promise<{ success: TargetedSharingModel[] }> {
		const response = await CrudApi.Api(c => c.post(`/${this.resource}/share-to-emails`, {
			emails,
			message
		}));
		return {
			success: response.success.map((obj: any) => this.create(obj)),
		}
	}
}