
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiInformationOutline } from '@mdi/js';
import { ComparativeSampleDataMixin } from '@/mixins/ComparativeSampleDataMixin';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';
import { FeatureFlagMixin } from '@/mixins';
import { RegisterForAssessmentMixin } from '@/mixins/RegisterForAssessmentMixin';

@Component
export default class ComparativeDataNoComparisonAlert extends Mixins(
	ComparativeSampleDataMixin,
	FeatureFlagMixin,
	RegisterForAssessmentMixin,
) {
	mdiInformationOutline = mdiInformationOutline;
	@Prop({ required: true }) state: ComparativeDataPageState;
	get ShowAlert(): boolean{
		return (this.state.IsNotEnoughData || this.state.IsNoAssessment) && !this.useSampleData;
	}
}
