
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronRight } from '@mdi/js';
import { AthleteMetric } from '../../../../types/enums';
import { VuetifyMixin } from '../../../mixins';
import ProgressBar from '../../charts/ProgressBar.vue';
import AssessmentDataInfoProvider from '../../hoc/AssessmentDataInfoProvider.vue';
import ExpandingCard from '../../ui/ExpandingCard.vue';
import MetricChart from './MetricChart.vue';
import PerformanceLevelPreview from './PerformanceLevelPreview.vue';
import { AthleteAssessmentDataModel } from '../../../models/athlete/AthleteAssessmentDataModel';
import { performanceCategoryToColor } from '../../../pipes';

import RulerSvg from '../../svg/RulerSvg.vue';
import SpeedometerSvg from '../../svg/SpeedometerSvg.vue';
import StopwatchSvg from '../../svg/StopwatchSvg.vue';
import LightningSvg from '../../svg/LightningSvg.vue';
import AgilitySvg from '../../svg/AgilitySvg.vue';
import HeartVitalSvg from '../../svg/HeartVitalSvg.vue';


@Component({
	components: {
		AssessmentDataInfoProvider,
		ExpandingCard,
		MetricChart,
		ProgressBar,
		PerformanceLevelPreview,

		RulerSvg,
		SpeedometerSvg,
		StopwatchSvg,
		LightningSvg,
		AgilitySvg,
		HeartVitalSvg,
	}
})
export default class AthleteStatCard extends Mixins(VuetifyMixin){
	mdiChevronRight = mdiChevronRight;
	performanceCategoryToColor = performanceCategoryToColor;
	@Prop({ required: true }) private metric: AthleteMetric;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ required: true }) private assessmentData: AthleteAssessmentDataModel;
	@Prop({ type: Boolean, default: false }) private hover: boolean;
	@Prop({ type: Boolean, default: false }) private loading: boolean;
	@Prop({ type: Boolean, default: false }) private isExpanded: boolean;
	@Prop({ type: Boolean, default: false }) private hideSeeDetails: boolean;
	@Prop({ type: Number, default: '100%' }) private height: string;
	@Prop({ type: Boolean, default: true }) private loadData: boolean;

	updateIsExpanded(value: boolean): void{
		this.isExpanded = value;
		this.$emit('update:is-expanded', value);
	}

	get RootStyle(): Record<string,any>{
		return {
			'height': this.height,
			'flex-basis': '300px',
		};
	}
}
