
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * To use this component, visit a youtube video and clik the share link. Select embed and copy the iframe code. Take the url value from the src attribute and hand it to this component under embedUrl
 */
@Component
export default class EmbeddedYoutubeVideo extends Vue{
	@Prop({ default: "315" }) height: string;
	@Prop({ default: "100%" }) width: string;
	@Prop({ required: true }) embedUrl: string;
	@Prop({ default: true, type: Boolean }) allowFullscreen: boolean;
}
