
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetricsMixin } from '@/mixins/AthleteMetricsMixin';
import { capitalize } from '@/pipes';
import { AthleteMetric } from '@best-athletes/ba-types';
import { VuetifyMixin } from '@/mixins';
@Component
export default class MetricWithIcon extends Mixins(AthleteMetricsMixin, VuetifyMixin){
	@Prop({ default: 32, type: Number }) size: number;
	@Prop({ required: true }) metric: AthleteMetric;
	@Prop({ default: null }) color: string;
	get Color(): string{
		if(this.color){
			return this.getColor(this.color);
		}else{
			return this.getMetricColor(this.metric);
		}
	}
	get MetricCapitalized(){
		return capitalize(this.metric);
	}
	get TextStyle(): Record<string, any>{
		return {
			'line-height': `${this.size}px`,
		};
	}
}
