
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronRight } from '@mdi/js';
import { AthleteMetric } from '../../../../types/enums';
import { AthleteMetricsMixin } from '../../../mixins';
import { performanceCategoryToColor } from '../../../pipes';


@Component({
	components: {}
})
export default class MetricPercentileCard extends Mixins(AthleteMetricsMixin){
	mdiChevronRight = mdiChevronRight;
	performanceCategoryToColor = performanceCategoryToColor;
	@Prop({ required: true }) metric: AthleteMetric;
	@Prop() value: string | number;
	@Prop({ required: true }) percentile: number;
	/** Expects PageState.IsLoading */
	@Prop({ default: false, type: Boolean }) loading: boolean;
	/** Expects PageState.IsReady */
	@Prop({ default: false, type: Boolean }) ready: boolean;
	/** If true, show empty state regardless of loading / ready states */
	@Prop({ default: false, type: Boolean }) empty: boolean;
	/**
	 * Allow switching to metric view
	 */
	@Prop({ default: false, type: Boolean }) showMetric: boolean;
	updateShowMetric(showMetric: boolean): void{
		this.$emit('update:show-metric', showMetric);
		this.showMetric = showMetric;
	}
	get ShowMetric(): boolean{
		if(this.empty){
			return false;
		}
		else{
			return this.showMetric;
		}
	}

	get Percentile(): string | number{
		if(this.empty) return "--";
		return this.percentile;
	}

	get ButtonText(): string{
		if(this.ShowMetric){
			return "See Score";
		}
		return "See Metric";
	}

	get TitleTextColorClass(): string{
		if(this.ShowMetric){
			return 'white--text';
		}
		return "baColorPrimaryText--text";
	}

	get MetricIconColor(): string{
		if(this.ShowMetric){
			return 'white';
		}
		return this.MetricColor;
	}
	get MetricColor(): string{
		return `${this.getMetricColor(this.metric)}`;
	}
	get MetricColorTextClass(): string{
		return `${this.MetricColor}--text`;
	}

}
