
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiRadar } from '@mdi/js';
import { ComparativeSampleDataMixin } from '@/mixins/ComparativeSampleDataMixin';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import ComparativeDataNoComparisonAlert from '@/components/profile/athlete/ComparativeDataNoComparisonAlert.vue';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';

@Component({
	components:{
		ComparativeDataNoComparisonAlert,
	}
})
export default class ManageSampleDataDialog extends Mixins(
	VuetifyMixin,
	ComparativeSampleDataMixin,
) {
	mdiRadar = mdiRadar;
	@Prop({ required: true }) state: ComparativeDataPageState;
}
