
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiAccount, mdiLock } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import { formatDatePrettyLong } from '@/helpers/date';
import { DataTableHeader } from 'vuetify';
import { DataTableSortMixin, MyAthleteMixin } from '@/mixins';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import { ViewTrackingRecordViewModel } from '@/models/viewTrackingRecord/viewTrackingRecordViewModel';

@Component({
	components: {
		SharingMetric,
		SubscriptionInfoProvider,
	}
})
export default class SharingHistory extends Mixins(DataTableSortMixin, MyAthleteMixin, VuetifyMixin){
	formatDatePrettyLong = formatDatePrettyLong;
	mdiMagnify = mdiMagnify;
	mdiAccount = mdiAccount;
	mdiLock = mdiLock;

	@Prop({ default: "" }) search: string;
	updateSearch(search: string): void{
		this.$emit('update:search', search);
	}

	headers: DataTableHeader<any>[] = [
		{
			text: '',
			value: '',
			sortable: false,
		},
		{
			text: 'Name',
			value: 'name',
			sortable: false,
		},
		{
			text: 'Email',
			value: 'email',
			sortable: false,
		},
		{
			text: 'Date',
			value: 'date',
			sortable: true,
		},
	];

	showShareModal: boolean = false;
	/** Total number of records ie. Profile Shares */
	@Prop({ type: Number, required: true }) value: number;
	/** Label for total number; "Profile Shares", "Profile Views" */
	@Prop({ type: String, required: true }) label: string;
	@Prop({ default: [] }) records: ViewTrackingRecordViewModel[];

	submitShareProfile(payload: { emails: string[], message: string }): void{
		this.$emit('share', payload);
	}

}
