import { render, staticRenderFns } from "./ComparativeDataUsingSampleDataAlert.vue?vue&type=template&id=d73ca482&scoped=true&"
import script from "./ComparativeDataUsingSampleDataAlert.vue?vue&type=script&lang=ts&"
export * from "./ComparativeDataUsingSampleDataAlert.vue?vue&type=script&lang=ts&"
import style0 from "./ComparativeDataUsingSampleDataAlert.vue?vue&type=style&index=0&id=d73ca482&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d73ca482",
  null
  
)

export default component.exports