
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import Dialog from '@/components/ui/Dialog.vue';
import SubscriptionPlanCard from '@/components/subscriptions/SubscriptionPlanCard.vue';
import SubscriptionPlanInfoProvider from '@/components/hoc/SubscriptionPlanInfoProvider.vue';

@Component({
	components: { Dialog, SubscriptionPlanInfoProvider, SubscriptionPlanCard }
})
export default class SubscriptionsDialog extends Mixins(VuetifyMixin){
	@Prop({ default: false, type: Boolean }) hideTitle: boolean;
	@Prop({ default: false, type: Boolean }) inline: boolean;
	@Prop({ default: false, type: Boolean }) show: boolean;
	updateShow(show: boolean): void{
		this.$emit('update:show', show);
	}

	get IsSmall(): boolean{
		return this.$vuetify.breakpoint.xsOnly;
	}

	close(): void{
		this.$emit('close');
	}
}
