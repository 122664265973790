
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin } from '@/mixins';
import { SchoolModel, SportDetailModel, CoachDetailModel, LocationModel } from '@/models/school/SchoolModel';
import { schoolApi } from '@/api/SchoolApi';
import { DummySchool } from '@/models/school/schoolDummyModel';
import { SportName, SportGender, Gender } from '@/../types/enums';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class SchoolDetailProvider extends Mixins(AppHostnameMixin){
	@Prop({ default: 'div' }) private as: string;
	@Prop({ type: String, default: null }) schoolId: string | null;
	@Prop({ type: String, default: null }) locationId: string | null;
	@Prop({ type:String, default:null}) athleteSport: SportName | null;
	@Prop({ type: String }) parentId: string;
	@Prop({ type:String, default: Gender.Male }) gender: Gender | null;
	@Prop({ type:Boolean, default:false}) enabled: boolean;

	ready = false;
	error = false;
	featureAvailable = false;

	schoolDetail: SchoolModel | null = null;
	async created():Promise<void> {
		await this.loadSchoolDetails();
	}

	get SportGender(): SportGender {
		return this.gender.toLowerCase() === Gender.Male ? SportGender.MEN : SportGender.WOMEN;
	}
	get SpecificSchool(): SchoolModel | null {
		return this.schoolDetail;
	}
	get LocationIndex(): number | null {
		if (this.SpecificSchool === null) {
			return null;
		}
		const index = this.SpecificSchool.locations.findIndex(location => location.id === this.locationId);
		return index >= 0 ? index : 0;
	}
	get LocationDetail(): LocationModel | null {
		if (this.SpecificSchool === null || this.LocationIndex === null) {
			return null;
		}

		const locIndex = this.LocationIndex !== null && this.LocationIndex < this.schoolDetail.locations.length  ? this.LocationIndex : 0;
		return this.SpecificSchool.locations[locIndex];
	}
	get SportDetail(): SportDetailModel | null {
		if (this.LocationDetail === null) {
			return null;
		}
		const sportDetail = this.LocationDetail.sports.find(sport => sport.name === this.athleteSport && sport.gender === this.SportGender);
		
		return sportDetail !== undefined ? sportDetail : null;
	}
	get Coaches(): CoachDetailModel[] | null {
		return this.SportDetail !== null ? this.SportDetail.coaches : null;
	}

	async loadSchoolDetails():Promise<void> {
		try{
			this.ready = false;
			this.schoolDetail = this.enabled ? 
				await schoolApi.findById(this.schoolId, undefined, { headers: {'Parent-ID': this.parentId}}) : 
				DummySchool;
		}
		catch(error){
			console.error("Failed to load School Detail Page", error);
			this.error = true;
		}

		this.ready = true;
	}
	
	get SlotProps(): Record<string, any> {
		return {
			schoolDetail: this.SpecificSchool,
			locationDetail: this.LocationDetail,
			sportDetail: this.SportDetail,
			coachesDetail: this.Coaches,
			LocationIndex: this.LocationIndex,
			Loading: this.Loading,
			Ready: this.Ready,
			Error: this.error,
			FeatureAvailable: this.featureAvailable
		}
	}
	get Loading(): boolean {
		return !this.ready;
	}
	get Ready(): boolean {
		return this.ready;
	}
}
