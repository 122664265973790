
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins';

@Component
export default class CheckboxFilter extends Mixins(VuetifyMixin){
	@Prop({ default: []}) items: {text: string | number , value: string | number, disabled: boolean }[];
	@Prop({ default: "Default Title"}) title: string;
	@Prop({ default: true, type: Boolean }) multiple: boolean;

	@Prop() value: any[];

	get Value(): any[]{ 
		return this.value;
	}
	set Value(newValue: any[]) {
		// Will return the last selected item if multiple is turned of
		if (newValue !== null && newValue.length >= 1){
			this.$emit('input', this.multiple ?  newValue : [newValue[newValue.length - 1]])
		}
		else {
			this.$emit('input', [])
		}
	}
}
