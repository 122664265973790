
import { mdiInformationOutline } from '@mdi/js';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, AnalyticsAssessmentFilter, RadarChartSeries } from '@best-athletes/ba-types';
import { formatDatePrettyShort } from '@/helpers/date';
import RadarChartComparative, { METRIC_LABEL_MAP } from "@/components/charts/RadarChartComparative.vue";
import MetricPercentileCard from "@/components/profile/athlete/MetricPercentileCard.vue";
import ComparativeDataProvider, { CompareAgainstType, CompareAgainstValue } from '@/components/hoc/ComparativeDataProvider.vue';
import { CurrentAthleteMixin, DebounceMixin, VuetifyMixin, AthleteMetricsMixin, FeatureFlagMixin } from '@/mixins';
import { assessmentToMetric } from '@/pipes/assessment-to-metric.pipe';
import { assessmentMetricValueOrNull } from '@/pipes/assessment-metric-value-or-null.pipe';
import { AthleteAssessmentDataModel } from '@/models';
import { ComparativeSampleDataMixin } from '@/mixins/ComparativeSampleDataMixin';
import ManageSampleDataDialog from '@/components/profile/athlete/ManageSampleDataDialog.vue';
import ComparativeDataNoComparisonAlert from '@/components/profile/athlete/ComparativeDataNoComparisonAlert.vue';
import ComparativeDataUsingSampleDataAlert from '@/components/profile/athlete/ComparativeDataUsingSampleDataAlert.vue';
import { ContextMenuItem } from '@best-athletes/ba-types';

@Component({
	components: {
		MetricPercentileCard,
		ComparativeDataProvider,
		RadarChartComparative,
		ManageSampleDataDialog,
		ComparativeDataNoComparisonAlert,
		ComparativeDataUsingSampleDataAlert,
	}
})
export default class AthleteComparativeDataCard extends Mixins(
	AthleteMetricsMixin,
	VuetifyMixin,
	CurrentAthleteMixin,
	DebounceMixin,
	FeatureFlagMixin,
	ComparativeSampleDataMixin,
) {
	mdiInformationOutline = mdiInformationOutline;
	mdiCloudQuestion = "M 38.7 20.06 C 37.34 13.18 31.28 8 24 8 C 18.22 8 13.2 11.28 10.7 16.06 C 4.68 16.72 0 21.8 0 28 A 12 12 90 0 0 12 40 H 38 A 10 10 90 0 0 48 30 C 48 24.72 43.9 20.44 38.7 20.06 M 26 34 H 22 V 30 H 26 V 34 M 29.6 23.64 C 29 24.42 28.26 25 27.34 25.5 C 26.82 25.82 26.48 26.14 26.3 26.52 C 26.12 26.9 26 27.38 26 28 H 22 C 22 26.9 22.22 26.16 22.6 25.64 C 23 25.12 23.7 24.5 24.74 23.82 C 25.26 23.5 25.68 23.12 26 22.64 C 26.3 22.18 26.46 21.62 26.46 21 C 26.46 20.36 26.28 19.88 25.92 19.52 C 25.56 19.12 25 18.94 24.4 18.94 C 23.86 18.94 23.42 19.1 23 19.4 C 22.7 19.7 22.5 20.16 22.5 20.78 H 18.56 C 18.46 19.28 19 18 20.12 17.18 C 21.2 16.4 22.62 16 24.4 16 C 26.28 16 27.78 16.46 28.86 17.36 C 29.94 18.26 30.48 19.5 30.48 21 C 30.48 22 30.18 22.82 29.6 23.64 Z";
	formatDatePrettyShort = formatDatePrettyShort;
	compareAgainst: CompareAgainstValue = null;

	@Prop({ type: Boolean, default: false }) hideActions: boolean;

	showMetrics: boolean = false;
	updateShowMetric(showMetrics: boolean): void{
		this.showMetrics = showMetrics;
	}
	showComparisonInfo: boolean = false;

	get MoreMenuItems(): ContextMenuItem[]{
		const items: ContextMenuItem[] = [
			{
				text: "Add assessment results",
				click: () => this.$emit('click:add-assessment'),
			},
			{
				text: "See past assessment results",
				click: () => this.$emit('click:see-assessment-results'),
			},
		];
		if(this.feature('DIS-140-book-assessment-page')){
			items.push({
				text: "Register for assessment event",
				click: () => this.$emit('click:register-assessment'),
			})
		}
		return items;
	}

	get AllAthleteMetrics(): AthleteMetric[]{
		return Object.entries(METRIC_LABEL_MAP).map(([,val]) => val);
	}
	created(): void {
		this.compareAgainst = {
			text: 'My Age',
			value: this.CurrentAthleteProfile.AgeGroup,
			type: CompareAgainstType.AGE_GROUP,
		};
	}
	get ComparisonFilters(): AnalyticsAssessmentFilter[]{
		if(!this.CurrentAthleteIsReady) return [];
		const filters: AnalyticsAssessmentFilter[] = [{
			"category": "age_group", 
			"value": this.CurrentAthleteProfile.AgeGroup,
			"operator": "="
		}]
		return filters;
	}

	getMetricPercentile(metric: AthleteMetric, chartData: RadarChartSeries[]): number | null{
		const [data] = chartData;
		const index = this.AllAthleteMetrics.findIndex(m => m === metric);
		return data.values[index] ?? null;
	}
	getMetricFromAssessmentValue(assessment: AthleteAssessmentDataModel, metric: AthleteMetric): string{
		const data = assessmentToMetric(assessment, metric);
		return assessmentMetricValueOrNull(data.value);
	}

	get MdAndUp(): boolean{
		return this.$vuetify.breakpoint.mdAndUp;
	}
	get MdAndDown(): boolean{
		return this.$vuetify.breakpoint.mdAndDown;
	}
	get StatCardsWrap(): boolean{
		return this.IsMobile;
	}
	get ActionButtonsBottom(): boolean{
		return this.IsMobile;
	}

}
