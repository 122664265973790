
import ComparativeDataProvider from '@/components/hoc/ComparativeDataProvider.vue';
import { CurrentAthleteMixin, VuetifyMixin, AthleteMetricsMixin, LocalForageMixin } from '@/mixins';
import { CategoryScale, Chart as ChartJS, ChartOptions, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetricValues, AthleteMetric, AnalyticsAssessmentFilter } from '@best-athletes/ba-types';
import MetricWithIcon from '@/components/profile/athlete/MetricWithIcon.vue';
import { capitalize } from "@/pipes/capitalize.pipe";
import { getAnalyticsGender } from "@/pipes/analytics-gender.pipe";
import { AthleteAssessmentDataModel } from '@/models';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export enum TimeFrameOptions {
	ALL,
	LAST6MONTHS,
	LASTYEAR,
}

@Component({
	components: {
		ComparativeDataProvider,
		LineChartGenerator,
		MetricWithIcon
	}
})
export default class AthleteComparativeDataMyProgress extends Mixins(VuetifyMixin, CurrentAthleteMixin, AthleteMetricsMixin, LocalForageMixin){
	capitalize = capitalize;
	localForagePersistFields: Array<string | [string, any]> = [['timeFrameSelected', TimeFrameOptions.ALL]]
	@Prop({type: Boolean, default: false}) useSampleData: boolean;
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get AthleteMetricIcons(): Record<AthleteMetric, string> {
		return {
			[AthleteMetric.Power]: '$lightning',
			[AthleteMetric.Acceleration]: '$speedometer',
			[AthleteMetric.Speed]: '$stopwatch',
			[AthleteMetric.Agility]: '$agiliy',
			[AthleteMetric.Recovery]: '$recovery',
		}
	}
	get MetricIconSize(): number {
		if (this.$vuetify.breakpoint.smAndUp) {
			return 30
		} else {
			return 15
		}
	}
	get BaseFilters(): AnalyticsAssessmentFilter[] {
		const filters: AnalyticsAssessmentFilter[] = [
			{
				"category": "gender",
				"value": getAnalyticsGender(this.CurrentAthleteProfile.gender),
				"operator": "="
			},
		];
		
		return filters;
	}

	/**
	 * Returns a filter function that filters the assessments that should be displayed based on the time frame selected
	 */
	get LineChartFilter(): (AthleteAssessmentDataModel) => boolean {
		if (this.timeFrameSelected !== TimeFrameOptions.ALL) {
			let date = new Date();
			switch (this.timeFrameSelected) {
			case TimeFrameOptions.LAST6MONTHS:
				date.setMonth(date.getMonth() - 6)
				break;
			case TimeFrameOptions.LASTYEAR:
				date.setFullYear(date.getFullYear() - 1);
				break;
			default:
				break;
			}
			return (assessment: AthleteAssessmentDataModel) => assessment.assessmentDate.getTime() >= date.getTime();
		}
		return () => true;
	}

	get LineChartOptions(): ChartOptions {
		return {
			scales: {
				x: {
					grid: {
						display: false,
					},
					title: {
						display: true,
						padding: 20,
						text: 'Assessment Date',
						font: {
							family: 'Rubik',
							size: 14,
							weight: '500',
						}
						
					},
					ticks: {
						minRotation: 0,
					}
				},
				y: {
					grid: {
						display: false,
					},
					title: {
						display: !this.IsMobile,
						padding: 20,
						text: 'Percentiles',
						font: {
							family: 'Rubik',
							size: 14,
							weight: '500',
						}
						
					},
					min: 0,
					max: 100,
					offset: true
				},
			},
			plugins: {
				legend: {
					display: false
				}
			},
			maintainAspectRatio: false,
			responsive: true,
		}
	}

	timeframeOptions = [
		{ text: 'All time', value: TimeFrameOptions.ALL },
		{ text: 'Last 6 months', value: TimeFrameOptions.LAST6MONTHS },
		{ text: 'Last year', value: TimeFrameOptions.LASTYEAR },
	];
	timeFrameSelected = TimeFrameOptions.ALL;
}
