
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, MyTeamsMixin, CurrentAthleteMixin, AuthMixin, FeatureFlagMixin } from "@/mixins";
import convert from "convert-units";
import { formatDate, calculateAge } from "@/helpers/date";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";

import { Country, Language } from "@/../types/enums";

import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import { TeamModel } from "@/models/team";
import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';

import TeamMiniCard from "@/components/ui/TeamMiniCard.vue";

import RecruitingProfileInfoProvider from "@/components/hoc/RecruitingProfileInfoProvider.vue";
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";
import AthleteVerifiedAssessmentsCard from "@/components/profile/athlete/AthleteVerifiedAssessmentsCard.vue";
import AthleteComparativeDataCard from "@/components/profile/athlete/AthleteComparativeDataCard.vue";
import AthleteSelfAssessmentSummary from "@/components/profile/athlete/AthleteSelfAssessmentSummary.vue";
import AthleteRyzerReport from "@/components/profile/athlete/AthleteRyzerReport.vue"
import AthleteSpqReportCard from "@/components/profile/athlete/AthleteSpqReportCard.vue";

import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';
import ClipPlayerStudio from '@/components/videoClipPlayer/ClipPlayerStudio.vue';
import { VideoClipModel } from '@/models/video/VideoClipModel';

import { notificationStore } from '@/store';

import {
	mdiFacebook,
	mdiLinkedin,
	mdiTwitter,
	mdiPlayCircle,
	mdiPencil,
	mdiPhone,
	mdiEmail,
	mdiInstagram,
	mdiShareVariant,
	mdiWeb,
} from "@mdi/js";
import { SocialMedia } from "@/../types/enums";
import { AthleteTeamDashboard, AthleteComparativeDataSummary, CoachViewAthleteVideos } from "@/../types/constants/web_client_user.routes";

import ShareProfile from '@/components/profile/recruiting/ShareProfile.vue';
import { AthleteMetric } from '@best-athletes/ba-types';

@Component({
	components: {
		RecruitingProfileInfoProvider,
		RecruitingProfileInfo,
		CopyToClipboard,
		TeamMiniCard,
		AthleteScoutingReportsCard,
		AthleteVerifiedAssessmentsCard,
		AthleteComparativeDataCard,
		AthleteSelfAssessmentSummary,
		AthleteRyzerReport,
		AthleteSpqReportCard,
		VideoLibraryProvider,
		VideoThumbnail,
		ClipPlayerStudio,
		ShareProfile,
	}
})
export default class RecruitingProfileCard extends Mixins(VuetifyMixin, AppHostnameMixin, FeatureFlagMixin, CurrentAthleteMixin, MyTeamsMixin, AuthMixin) {
	@Prop({ type: String, default: null }) athleteId: string | null;
	@Prop({ required: true, default: null }) athlete: AthleteProfileModel;
	@Prop({ default: null }) assessmentData: AthleteAssessmentDataModel;
	@Prop({ required: true, default: null }) teams: TeamModel[];
	@Prop({ required: false, default: true }) menuBtnVisible: boolean;
	@Prop({ type: Boolean, default: false }) hideBio: boolean;
	@Prop({ default: null }) sharingUrlId: string | null;
	
	iconEmail = mdiEmail;
	iconPhone = mdiPhone;

	mdiFacebook = mdiFacebook;
	mdiLinkedin = mdiLinkedin;
	mdiTwitter = mdiTwitter;
	mdiPlayCircle = mdiPlayCircle;
	mdiPencil = mdiPencil;
	mdiShareVariant = mdiShareVariant;
	mdiWeb = mdiWeb;
	
	// Unit conversion
	convert = convert;

	// Enums
	Country = Country;
	Language = Language;
	SocialMedia = SocialMedia;

	shareProfileClick: boolean = false;

	get AthleteId(): string | null{
		if(this.AthleteAppActive){
			return this.CurrentAthleteId;
		}
		return this.athleteId;
	}

	get UseComparativeDataV2(): boolean{
		return this.feature<number>('DIS-84-comparative-data-page') == 2;
	}

	get CurrentTeam(): TeamModel
	{
		return this.teams[0]
	}

	get HasExtendedAbout(): boolean {
		return( this.athlete.previousClubs != "" || this.athlete.highestLevelPlayed != "" || this.athlete.currentLevel != "");
	}
	get HasAbout(): boolean {
		return( this.athlete.shortBio != "" || this.HasExtendedAbout);
	}

	get HasBio(): boolean {
		return( this.athlete.gender != null || this.athlete.birthDate != null || this.athlete.height > 0 || this.athlete.weight > 0 || this.athlete.city != "" || this.athlete.country != "")
	}

	get HasContactInfo(): boolean {
		return ( this.athlete.email != "" || this.athlete.phoneNumber != "" );
	}

	get HasEducation(): boolean {
		return ((this.athlete.highschoolGPA > 0) ||
				(this.athlete.highschoolName != "") ||
				(this.athlete.highschoolGradYear > 0) ||
				(this.athlete.anticipatedStudy != "") ||
				(this.athlete.SATScore > 0) );
	}

	get HasExtendedBio(): boolean {
		return !!((this.athlete.languagesSpoken && this.athlete.languagesSpoken.length) || 
				(this.athlete.citizenships && this.athlete.citizenships.length))
	}

	formatDate = formatDate;
	calculateAge = calculateAge;

	highlightReelPlayerVisible: boolean = false;
	currentClip: VideoClipModel | null = null;
	HighlightReelClips(VideoClips: VideoClipModel[]): VideoClipModel[]{
		return VideoClips.filter(clip => clip.inHighlightReel);
	}
	$refs: {
		highlightClipPlayer: ClipPlayerStudio,
		videoClipPlayer: ClipPlayerStudio,
	};
	playAll(firstClip: VideoClipModel): void{
		this.currentClip = firstClip;
		this.highlightReelPlayerVisible = true;
		this.$refs.highlightClipPlayer.playAll();
	}

	goToVideoLibrary(athleteId: string): void{
		this.$router.push({
			name: CoachViewAthleteVideos,
			params:{
				...this.$route.params,
				athleteId,
				sharingUrlId: this.sharingUrlId
			}
		});
	}

	shareProfile(): void{
		this.shareProfileClick = true;
	}

	get CoachShareUrl(): string {
		return  'https://' + this.CoachAppUrl + this.athlete.PublicSharingUrl;
	}

	submitShareProfile(payload: { emails: string[], message: string }): void{
		this.$emit('share', payload);
	}

	viewTeam(teamId: string): void{
		this.$router.push({
			name: AthleteTeamDashboard,
			params: {
				teamId
			}
		});
	}	

	get SocialMediaColorAndIcon(): Partial<Record<SocialMedia, {icon: string, color: string, size:string, style: string}>> {
		return {
			"Facebook": { icon: mdiFacebook, color: "#4267b2" , size: 'large', style: ""},
			"Instagram": { icon: mdiInstagram, color: "#e1306c", size: 'large', style: ""},
			"LinkedIn": { icon: mdiLinkedin, color: "#0075b5", size: 'large', style: ""},
			"Twitter": { icon: mdiTwitter, color: "#1DA1F2", size: 'large', style: ""},
			"TikTok": { icon: '$vuetify.icons.tiktok' as any, color: "#010101", size: 'large', style: "width:30px" },
			"Website": { icon: mdiWeb, color: "#005AA8", size: 'large', style:"" },
		}
	}

	onViewComparativeData(metric: AthleteMetric): void{
		if(this.assessmentData === null) return;

		// view the comparative data for the specified metric for this athlete
		this.$router.push({
			name: AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.athleteId,
			}
		});
		// go to the top of the page
		this.$vuetify.goTo(0);
	}

	get ShowPersonality(): boolean{
		return false;
	}

	get TitleStyle(): Record<string, any>{
		return {
			color: this.getColor('baColorVibrantBlueText')
		}
	}

}
