
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { mdiShareVariant, mdiCheck, mdiCheckCircle, mdiLock } from "@mdi/js";
import CopyToClipboard from "../../ui/CopyToClipboard.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import { FormRulesMixin, MyAthleteMixin } from '@/mixins';
import { UpgradeNowMixin } from '@/mixins/UpgradeNowMixin';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';

@Component({
	components: { CopyToClipboard, ConfirmationDialog, SubscriptionInfoProvider }
})
export default class ShareProfile extends Mixins(FormRulesMixin, VuetifyMixin, MyAthleteMixin, UpgradeNowMixin) {
	mdiShareVariant = mdiShareVariant;
	mdiCheck = mdiCheck;
	mdiCheckCircle = mdiCheckCircle;
	mdiLock = mdiLock;
	@Prop({ default: false, type: Boolean }) show: boolean;
	@Prop({ default: null }) url: string | null;
	@Prop({ type: Boolean, default: false }) noActivator: boolean;
	clicked: boolean = false;
	emails: string[] = [];
	message: string = "";
	shareButtonText = "Share Profile";

	// determine if sharing is available
	isSharingAvailable(numSharesAvailable: number | null)
	{
		if (numSharesAvailable === null) return true;
		if (numSharesAvailable === 0) return false;
		return true;
	}
	isSharingReady(numSharesAvailable: number | null): boolean{
		if (!this.isSharingAvailable(numSharesAvailable)) return false;
		if (this.emails.length > numSharesAvailable) return false;
		return true
	}

	// update the button message on hover if no shares are available
	onMouseoverShareButton(isSharesAvailable: boolean): void{
		if( !isSharesAvailable ){ this.shareButtonText = "Upgrade Now" }
	}
	onMouseleaveShareButton(): void{
		this.shareButtonText = "Share Profile";
	}

	// tooltip color is 'success' if shares are available
	getTooltipColor(isSharesAvailable: boolean)
	{
		if (isSharesAvailable) return "success";		
		return "error";
	}

	// event: share profile button clicked
	onClickShareProfile(show: boolean): void{
		 this.$emit('update:show', show); 
	}

	resetForm(): void{
		this.emails = [];
		this.message = "";
	}
	updateEmail(email: string[]): void{
		this.emails = email
	}
	updateMessage(message: string):void{
		this.message = message;
	}

	get NoActivator(): boolean{
		return this.noActivator;
	}
	get InvalidEmailsMessage(): string | undefined{
		if(this.InvalidEmails.length === 0) return undefined;
		return `Invalid emails: ${this.InvalidEmails.join(', ')}`;
	}
	get InvalidEmails(): string[]{
		return this.emails.filter(email => this.rulesOptionalEmail[0](email) !== true);
	}
	get FormValid(): boolean{
		return this.message.length > 0 && this.InvalidEmails.length === 0 && this.emails.length > 0;
	}
	get Url(): string | null {
		return this.url;
	}

	get Clicked(): Record<string, any> {
		return {
			backgroundColor: this.getColor("baColorGreen2"),
			clicked: this.clicked
		};
	}
	async shareProfile(loadPlanInformation:() =>  Promise<void>): Promise<void>{
		this.$emit("submit", {
			emails: this.emails,
			message: this.message
		});
		this.resetForm();
		
		this.onClickShareProfile(false); // Close the modal
		await loadPlanInformation();
	}
}
