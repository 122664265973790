
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import AthleteScoutingReportsInfoProvider from "@/components/hoc/AthleteScoutingReportsInfoProvider.vue";
import { DataTableHeader } from 'vuetify';
import { mdiEye } from '@mdi/js';
import { formatDate, formatDatePretty } from "@/helpers/date";
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { AuthMixin, FeatureFlagMixin } from '@/mixins';
import { AthleteClaimScoutingReportForm, AthleteScoutingReport } from '@/../types/constants/web_client_user.routes';
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";

@Component({
	components: {
		AthleteScoutingReportsInfoProvider,
		ScoutingReportSpiderChart,
		ScoutingReportScoreChip,
	}
})
export default class AthleteScoutingReportsCard extends Mixins(AuthMixin, FeatureFlagMixin, VuetifyMixin){
	@Prop({ default: true }) buttonsVisible: boolean;
	@Prop({ default: true }) historyVisible: boolean;
	@Prop({ default: null }) athleteId: string | null;

	mdiEye = mdiEye
	formatDate = formatDate
	formatDatePretty = formatDatePretty

	headers: Array<DataTableHeader> = [
		{ text: 'Date', value: 'createdAt', align: 'center'},
		{ text: 'Location', value: 'location'},
		{ text: 'Performance Score', value: 'performance', align: 'center'},
		{ text: 'Potential Score', value: 'potential', align: 'center'},
		{ text: '', value: 'blank'},
	]
	
	get HasScoutingReports(): boolean {
		return true;
	}

	addReport(): void{
		this.$router.push({
			name: AthleteClaimScoutingReportForm,
		});
	}
	goToScoutingReportDetailPage(item: ScoutingReportModel) {
		this.$router.push({
			name: AthleteScoutingReport,
			params:{
				reportId: item.id,
			}
		});
	}
}
