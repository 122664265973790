
import { AgeGroup, Under13AgeGroup } from '@best-athletes/ba-types';
import { formatDatePrettyShort, getShortMonthName } from '@/helpers';
import { CurrentAthleteMixin, DebounceMixin, FeatureFlagMixin, MyTeamsMixin,VuetifyMixin } from '@/mixins';
import { AthleteAssessmentDataModel } from '@/models';
import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';
import { TeamModel } from '@/models/team';
import { getMetricColor } from '@/pipes/metric-color.pipe';
import { getGenderTextPlural } from '@/pipes/gender-text-plural.pipe';
import { comparativeDataV2Store, comparativeDataV2SampleDataStore } from '@/store';
import ComparativeDataV2Module from '@/store/athletes/ComparativeDataV2.store';
import ComparativeDataV2SampleDataModule from '@/store/athletes/ComparativeDataV2SampleData.store';
import { AnalyticsAssessmentFilter, AthleteMetric, BaseSoccerPosition, GenericSpiderChart, RadarChartSeries, AnalyticsGender } from '@best-athletes/ba-types';
import { ChartData } from 'chart.js';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

export enum CompareAgainstType {
	TEAM,
	POSITION,
	AGE_GROUP,
	CUSTOM
}
export interface CompareAgainstTeam {
	text: string;
	type: CompareAgainstType.TEAM;
	value: TeamModel;
}
export interface CompareAgainstPosition {
	text: string;
	type: CompareAgainstType.POSITION;
	value: BaseSoccerPosition | string;
}
export interface CompareAgainstAge {
	text: string;
	type: CompareAgainstType.AGE_GROUP;
	value: AgeGroup | Under13AgeGroup;
}
export interface CompareAgainstCustom {
	text: string;
	type: CompareAgainstType.CUSTOM;
	value: 'custom';
}
export type CompareAgainstValue =
	| CompareAgainstTeam
	| CompareAgainstPosition
	| CompareAgainstAge
	| CompareAgainstCustom;

type ComparativeDataProviderSlotProps = {
	assessment: AthleteAssessmentDataModel;
	updateAssessment: ComparativeDataProvider['updateAssessment'];
	selectAssessment: ComparativeDataV2Module['selectAssessment'];
	assessmentList: AthleteAssessmentDataModel[];
	assessmentItems: { text: string; value: AthleteAssessmentDataModel }[];
	assessmentsState: ComparativeDataPageState;
	CompareAgainst: CompareAgainstValue[];
	CompareGroupNameFormat: string;
	chartState: ComparativeDataPageState;
	chartFilters: AnalyticsAssessmentFilter[];
	chartLowSampleSize: boolean;
	chartData: RadarChartSeries[];
	chartAvailable: boolean;
	lineChartData: ChartData;
	debouceUpdateLineChartData: () => Promise<void>
};

@Component({
	render(h) {
		return h(
			this.as,
			this.$slots.default
				? this.$slots.default
				: this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class ComparativeDataProvider extends Mixins(
	CurrentAthleteMixin,
	MyTeamsMixin,
	FeatureFlagMixin,
	DebounceMixin,
	VuetifyMixin
) {

	@Prop({ default: false, type: Boolean }) useSampleData: boolean;
	get ComparativeDataStore(): ComparativeDataV2Module | ComparativeDataV2SampleDataModule{
		if(this.useSampleData){
			return comparativeDataV2SampleDataStore;
		}
		return comparativeDataV2Store
	}
	@Prop({ default: 'div' }) as: string;
	@Prop({ required: true }) athleteId: string;

	/**
	 * Radar Chart data update flag
	 */
	@Prop({ default: false, type: Boolean }) updateCompareChartData: boolean;
	/**
	 * Line Chart data update flag
	 */
	@Prop({ default: false, type: Boolean }) updateLineChartData: boolean;
	/**
	 * Percentile calculation filters
	 */
	@Prop({ default: [] }) filters: AnalyticsAssessmentFilter[];
	@Watch('useSampleData') debounceUpdateChartSampleData(): void {
		if(this.useSampleData === false){
			this.debouceUpdateChartData();
		}
	}
	@Watch('filters') debounceUpdateChart(): void {
		if (this.updateChartData) {
			this.debouceUpdateChartData();
		}
		if (this.updateLineChartData) {
			this.debouceUpdateLineChartData();
		}
	}

	@Prop({ default: () => true, type: Function}) lineChartAssessmentFilters: () => boolean;

	get chartState(): ComparativeDataPageState{
		return this.ComparativeDataStore.spiderChartState;
	}
	get chartFilters(): AnalyticsAssessmentFilter[]{
		return this.ComparativeDataStore.spiderChartFilters;
	}
	get chartData(): RadarChartSeries[]{
		return this.ComparativeDataStore.spiderChartData
	}
	get chartLowSampleSize(): boolean{
		return this.ComparativeDataStore.spiderChartLowSampleSize;
	}
	get chartAvailable(): boolean{
		return this.ComparativeDataStore.spiderChartAvailable;
	}

	/**
	 * Returns a short text name to indicate the comparison group being used. ie; U16 Boys, U19 Girls, O20 Men
	 */
	get CompareGroupNameFormat(): string{
		const ageGroup = this.chartFilters.find(c => c.category === "age_group");
		const gender = this.chartFilters.find(c => c.category === "gender");
		return `${ageGroup ? `${ageGroup.value}`:''} ${gender ? `${getGenderTextPlural(gender.value as AnalyticsGender, { adult: ageGroup ? ageGroup.value === AgeGroup.O20 : false })}`:''}`;
	}

	get ChartData(): RadarChartSeries[] {
		return this.chartData;
	}

	async created(): Promise<void> {
		if (this.updateChartData && this.chartState.IsInitial) {
			await this.loadSpiderChart();
		}
		if (this.updateLineChartData && this.LineChartState.IsInitial) {
			await this.loadLineChartPercentlines();
		}
	}
	async mounted(): Promise<void> {
		await this.updateChartData();
	}

	async loadSpiderChart(): Promise<void> {
		await this.ComparativeDataStore.loadSpiderChart({
			athleteId: this.athleteId,
			filters: this.filters,
		});
	}
	async loadLineChartPercentlines(): Promise<void> {
		await this.ComparativeDataStore.loadLineChartPercentiles({
			athleteId: this.athleteId,
			assessmentFilters: this.lineChartAssessmentFilters
		});
	}

	async updateAssessment(
		assessment: AthleteAssessmentDataModel
	): Promise<void> {
		await this.ComparativeDataStore.selectAssessment({
			assessmentId: assessment.id
		});
		await this.debouceUpdateChartData();
	}

	get State(): ComparativeDataPageState {
		return this.ComparativeDataStore.spiderChartState;
	}

	get Assessment(): AthleteAssessmentDataModel {
		return this.ComparativeDataStore.assessment;
	}
	get AssessmentList(): AthleteAssessmentDataModel[] {
		return this.ComparativeDataStore.AssessmentList;
	}
	get AssessmentsState(): ComparativeDataPageState {
		return this.ComparativeDataStore.spiderChartState;
	}
	formatSelectText(a: AthleteAssessmentDataModel): string {
		return `${formatDatePrettyShort(a.assessmentDate)} Assessment`;
	}
	get AssessmentItems(): {
		text: string;
		value: AthleteAssessmentDataModel;
	}[] {
		return this.AssessmentList.map(a => ({
			text: this.formatSelectText(a),
			value: a
		}));
	}

	get CompareAgainst(): CompareAgainstValue[] {
		const comparisons: CompareAgainstValue[] = this.feature<boolean>(
			'DIS-83-compare-tab-teams-comparison'
		)
			? this.MyAthleteTeams.map(team => ({
				text: team.name,
				value: team,
				type: CompareAgainstType.TEAM
			}))
			: [];
		if (
			this.feature<boolean>(
				'DIS-83-compare-tab-radar-chart-position-select-option'
			) &&
			this.Assessment &&
			this.Assessment.playingPosition !== null &&
			this.Assessment.playingPosition !== ''
		) {
			comparisons.push({
				text: 'My Position',
				value: this.Assessment.playingPosition,
				type: CompareAgainstType.POSITION
			});
		}
		return [
			...comparisons,
			{
				text: 'My Age',
				value: this.CurrentAthleteProfile.AgeGroup,
				type: CompareAgainstType.AGE_GROUP
			},
			{
				text: 'Custom...',
				value: 'custom',
				type: CompareAgainstType.CUSTOM
			}
		];
	}

	async debouceUpdateChartData(): Promise<void> {
		if (this.updateCompareChartData) {
			this.ComparativeDataStore.setSpiderChartLoading();
			this.debounceCallback(
				'updateChartData',
				async () => await this.updateChartData(),
				300
			);
		}
	}
	async debouceUpdateLineChartData(): Promise<void> {
		if (this.updateLineChartData) {
			this.debounceCallback(
				'updateLineChartData',
				async () => await this.loadLineChartPercentlines(),
				300
			);
		}
	}
	async updateChartData(): Promise<void> {
		await this.ComparativeDataStore.loadSpiderChart({ athleteId: this.CurrentAthleteId, filters: this.filters });
	}

	get LineChartState(): ComparativeDataPageState{
		return this.ComparativeDataStore.lineChartState;
	}
	get LineChartPercentiles(): GenericSpiderChart[] {
		return this.ComparativeDataStore.lineChartPercentiles;
	}
	get ChartLabels(): string[] {
		return this.AssessmentList !== null && this.AssessmentList.length > 0
			? this.AssessmentList.filter(this.lineChartAssessmentFilters).map(
				assessment =>
					`${getShortMonthName(
						assessment.assessmentDate
					)}. ${assessment.assessmentDate.getDate()}`
			)
			: [];
	}

	get LineChartData(): ChartData {
		const datasets: ChartData['datasets'] = [];
		if (this.LineChartPercentiles !== null && this.LineChartPercentiles.length > 0) {
			datasets.push({
				label: 'Power',
				backgroundColor: this.getColor(
					getMetricColor(AthleteMetric.Power)
				),
				borderColor: this.getColor(getMetricColor(AthleteMetric.Power)),
				borderWidth: 2,
				pointRadius: 5,
				pointBorderColor: 'white',
				pointBorderWidth: 2,
				data: this.LineChartPercentiles.map(p => p.power)
			});
			datasets.push({
				label: 'Acceleration',
				backgroundColor: this.getColor(
					getMetricColor(AthleteMetric.Acceleration)
				),
				borderColor: this.getColor(
					getMetricColor(AthleteMetric.Acceleration)
				),
				borderWidth: 2,
				pointRadius: 5,
				pointBorderColor: 'white',
				pointBorderWidth: 2,
				data: this.LineChartPercentiles.map(p => p.acceleration)
			});
			datasets.push({
				label: 'Speed',
				backgroundColor: this.getColor(
					getMetricColor(AthleteMetric.Speed)
				),
				borderColor: this.getColor(getMetricColor(AthleteMetric.Speed)),
				borderWidth: 2,
				pointRadius: 5,
				pointBorderColor: 'white',
				pointBorderWidth: 2,
				data: this.LineChartPercentiles.map(p => p.speed)
			});
			datasets.push({
				label: 'Agility',
				backgroundColor: this.getColor(
					getMetricColor(AthleteMetric.Agility)
				),
				borderColor: this.getColor(
					getMetricColor(AthleteMetric.Agility)
				),
				borderWidth: 2,
				pointRadius: 5,
				pointBorderColor: 'white',
				pointBorderWidth: 2,
				data: this.LineChartPercentiles.map(p => p.agility)
			});
			datasets.push({
				label: 'Recovery',
				backgroundColor: this.getColor(
					getMetricColor(AthleteMetric.Recovery)
				),
				borderColor: this.getColor(
					getMetricColor(AthleteMetric.Recovery)
				),
				borderWidth: 2,
				pointRadius: 5,
				pointBorderColor: 'white',
				pointBorderWidth: 2,
				data: this.LineChartPercentiles.map(p => p.recovery)
			});
		}
		return {
			labels: this.ChartLabels,
			datasets
		};
	}

	get SlotProps(): ComparativeDataProviderSlotProps{
		return {
			assessment: this.Assessment,
			updateAssessment: (args: any) => this.updateAssessment(args),
			selectAssessment: (args: any) => this.ComparativeDataStore.selectAssessment(args),
			assessmentList: this.AssessmentList,
			assessmentItems: this.AssessmentItems,
			assessmentsState: this.AssessmentsState,
			CompareAgainst: this.CompareAgainst,
			CompareGroupNameFormat: this.CompareGroupNameFormat,
			chartState: this.chartState,
			chartLowSampleSize: this.chartLowSampleSize,
			chartData: this.chartData,
			chartAvailable: this.chartAvailable,
			chartFilters: this.chartFilters,
			lineChartData: this.LineChartData,
			debouceUpdateLineChartData: this.debouceUpdateLineChartData,
		};
	}
}
