
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, AthleteMetricValues } from '../../../../types/enums';
import * as Routes from '../../../../types/constants/web_client_user.routes';
import { VuetifyMixin } from '../../../mixins';
import { AthleteProfileModel } from '../../../models/athlete/AthleteProfileModel';
import { AthleteAssessmentDataModel } from '../../../models/athlete/AthleteAssessmentDataModel';

import AthleteStatCard from './AthleteStatCard.vue';
import StatsSummaryCard from './StatsSummaryCard.vue';
import { formatDatePretty } from '../../../helpers';
import { mdiLock} from '@mdi/js';

@Component({
	components:{
		AthleteStatCard,
		StatsSummaryCard,
	}
})
export default class AthleteVerifiedAssessmentsCard extends Mixins(VuetifyMixin){

	@Prop({ default: null }) athlete: AthleteProfileModel | null;
	@Prop({ default: null }) assessmentData: AthleteAssessmentDataModel | null;
	@Prop({ default: true }) addAssessmentBtnVisible: boolean;
	@Prop({ default: true }) compareBtnVisible: boolean;
	@Prop({ default: true }) compareFeatureAvailable: boolean;
	@Prop({ default: true }) detailsBtnVisible: boolean;
	@Prop({ default: true }) loadData: boolean;

	mdiLock = mdiLock;

	compareData(): void{
		this.expandMetric(AthleteMetric.Acceleration);
	}

	addAssessment(){
		this.$router.push({
			name: Routes.AthleteClaimAssessmentForm,
			params:{
				...this.$route.params,
			}
		});
	}

	expandMetric(metric: AthleteMetric): void{
		this.$emit('expandMetric', metric);
	}
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get AssessmentData(): AthleteAssessmentDataModel | null{
		return this.assessmentData;
	}
	get HasAssessment(): boolean{
		return this.assessmentData !== null && this.assessmentData !== undefined;
	}

	get FullName(): string{
		return `${this.AthleteProfile.firstName} ${this.AthleteProfile.lastName}`;
	}

	get AthleteProfile(): AthleteProfileModel{
		return this.athlete;
	}

	get LastAssessedDate(): string | undefined{
		if(!this.assessmentData) return undefined;
		return formatDatePretty(this.assessmentData.assessmentDate);
	}
}
