
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Stopwatch extends Vue{

	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 0 }) private duration: number;
	updateDuration(duration: number): void{
		this.duration = duration;
		this.$emit('update:duration', duration);
	}
	@Prop({ default: '00:00.000' }) private time: string;
	updateTime(time: string): void{
		this.time = time;
		this.$emit('update:time', time);
	}

	get SlotProps(): Record<string, any>{
		return {
			controls: {
				start: this.start,
				stop: this.stop,
				reset: this.reset,
				ejectTime: this.ejectTime,
			},
			isRunning: this.IsRunning,
			hasTime: this.HasTime,
			duration: this.duration,
			time: this.TimeFormatted,
		};
	}

	get IsRunning(): boolean{
		return this.running;
	}
	get HasTime(): boolean{
		return this.duration > 0;
	}
	get TimeFormatted(): string{
		return this.timeFmt;
	}

	durationMs: number = 0;
	timeFmt = '00:00.000'
	timeBegan = null
	timeStopped = null
	stoppedDuration = 0
	started = null
	running = false;

	start(): void {
		if(this.running) return;
		
		if (this.timeBegan === null) {
			this.reset();
			this.timeBegan = new Date();
		}

		if (this.timeStopped !== null) {
			this.stoppedDuration += ((+new Date()) - this.timeStopped);
			this.updateDuration(this.durationMs);
		}

		this.started = setInterval(this.clockRunning, 10);	
		this.running = true;
	}

	/**
	 * Emits updated times without stopping the stop watch
	 */
	ejectTime(): void {
		this.updateDuration(this.durationMs);
		this.updateTime(this.timeFmt);
	}

	stop(): void {
		this.running = false;
		this.timeStopped = new Date();
		clearInterval(this.started);
		this.ejectTime();
	}

	reset(): void {
		this.running = false;
		clearInterval(this.started);
		this.stoppedDuration = 0;
		this.timeBegan = null;
		this.timeStopped = null;
		this.timeFmt = "00:00.000";
		this.durationMs = 0;
		this.updateDuration(this.durationMs);
		this.updateTime(this.timeFmt);
	}

	clockRunning(): void{
		const currentTime = new Date()
			, timeElapsed = new Date(+currentTime - this.timeBegan - this.stoppedDuration)
			, min = timeElapsed.getUTCMinutes()
			, sec = timeElapsed.getUTCSeconds()
			, ms = timeElapsed.getUTCMilliseconds();
		this.durationMs = +timeElapsed;
		this.timeFmt =  
			this.zeroPrefix(min, 2) + ":" + 
			this.zeroPrefix(sec, 2) + "." + 
			this.zeroPrefix(ms, 3);
	}

	zeroPrefix(num: number, digit: number): string {
		var zero = '';
		for(var i = 0; i < digit; i++) {
			zero += '0';
		}
		return (zero + num).slice(-digit);
	}

}
