
import { Component, Mixins, Prop } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin, DebounceMixin } from "@/mixins";

import SharingInfoProvider, { loadSharingDataFn } from "@/components/hoc/SharingInfoProvider.vue";
import SharingHistory from "@/components/sharing/SharingHistory.vue";
import SharingViewHistory from "@/components/sharing/SharingViewHistory.vue";
import { Route } from 'vue-router';
import { DataOptions } from 'vuetify';
import { RepositoryQuery, QueryOptions } from 'types/interfaces';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';

@Component({
	components: {
		Page,
		SharingInfoProvider,
		SharingHistory,
		SharingViewHistory,
	},
	
})
export default class SharingSummary extends Mixins(DebounceMixin, VuetifyMixin) {
	search: string = "";
	tableOptions: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: ["date"],
		sortDesc: [true],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	@Prop({ type: String, default: null }) sharingId: string | null;
	get SharingId(): string{
		return this.sharingId;
	}
	@Prop({ type: String, default: 'shares' }) tab: string;
	toRoute(tab: string): Partial<Route>{
		return {
			...this.$route,
			params:{
				...this.$route.params,
				tab: tab,
			}
		};
	}

	get CurrentTab(): string{
		return this.tab;
	}

	tableUpdatePending: boolean = false;
	debounceUpdateTable(load: loadSharingDataFn): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable(load);
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(load: loadSharingDataFn): Promise<void>{
		return await this.loadSharingData(load);
	}

	tableLoading: boolean = false;
	async loadSharingData(load: loadSharingDataFn): Promise<void>{
		this.tableLoading = true;
		try{
			const query: RepositoryQuery<TargetedSharingModel> = {
				search: this.search,
				fields: ['email'],
				$match:{}
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await load({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.tableLoading = false;
		}
	}


}
