
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiPlay, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { VuetifyMixin } from '@/mixins';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import Carousel from '@/components/ui/Carousel.vue';
import VideoClipPreview from '@/components/ui/videos/VideoClipPreview.vue';

@Component({
	components: { Carousel, VideoClipPreview }
})
export default class AthleteVideoHighlightReel extends Mixins(VuetifyMixin){
	mdiPlay = mdiPlay;
	mdiChevronLeft = mdiChevronLeft;
	mdiChevronRight = mdiChevronRight;
	@Prop({ default: [] }) private videoClips: VideoClipModel[];

	get Empty(): boolean{
		return this.videoClips.length === 0;
	}
	get SubtitleText(): string{
		if(this.videoClips.length === 1){
			return `One clip saved to highlight reel`;
		}
		return `${this.videoClips.length} clips saved to highlight reel`;
	}

	videoClicked(item: VideoClipModel): void{
		this.$emit('click:video', item);
	}
}
