
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class LocationPin extends Mixins(VuetifyMixin){

	@Prop({ default: 'baColorLightBlue' }) private color: string;
	@Prop() private pictureUrl: string;
	@Prop({ default: 48 }) private height: number;
	@Prop({ default: 35 }) private width: number;

	defaultPictureUrl: string = 'https://cdn.bestathletes.co/static-assets/bestie-square-100x100.png';

	get Color(): string{
		return this.getColor(this.color);
	}
}
