import { getEnumValues } from './get-enum-values';
/**
 * PaymentIntent Statuses from type Stripe.PaymentIntent.Status
 */
export enum IntentStatus{
	canceled="canceled",
	processing="processing",
	requires_action="requires_action",
	requires_capture="requires_capture",
	requires_confirmation="requires_confirmation",
	requires_payment_method="requires_payment_method",
	succeeded="succeeded",
}
export const IntentStatusValues = getEnumValues<IntentStatus>(IntentStatus);