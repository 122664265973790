
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { MyAthleteMixin, RyzerMixin } from '@/mixins';
import RyzerAthleteTypeCard from '@/components/mindset/RyzerAthleteTypeCard.vue';
import RyzerScore from "@/components/ui/RyzerScore.vue";

@Component({
	components:{
		RyzerAthleteTypeCard,
		RyzerScore,
	},
})
export default class AthleteVerifiedAssessmentSummary extends Mixins(RyzerMixin, MyAthleteMixin){
	@Prop({ default: null }) athleteId: string | null;

	loading = false;

	// TODO: read athlete type from database
	get isLoading(): boolean{
		return this.loading;
	}
}
