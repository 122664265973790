
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { getModule } from "vuex-module-decorators";
import SchoolModule from "@/store/school/School.store";
import { mdiFormatListBulleted, mdiHeart, mdiHeartOutline, mdiSchool } from "@mdi/js";
import * as Routes from "../../../../types/constants/web_client_user.routes";
import { userStore } from "@/store";
import { Route } from "vue-router";
import { DataOptions } from "vuetify";
import { SchoolModel, ESSchoolModel } from "@/models/school/SchoolModel";
import FavouriteListProvider from "@/components/hoc/FavouriteListProvider.vue";
import { CurrentAthleteMixin } from "@/mixins";
import SchoolCard from "./SchoolCard.vue";
import ButtonToggle from "../../ButtonToggle.vue";
import SchoolDetailProvider from "@/components/hoc/SchoolDetailProvider.vue";
import { FavouriteListModel } from "@/models/favouriteList/FavouriteListModel";
import { RepositoryQuery, QueryOptions } from "types/interfaces";
import { ESDummySchoolList } from '@/models/school/schoolDummyModel';
import { SchoolFavouriteItem, SchoolFavoriteListModel } from '@/models/favouriteList/SchoolFavoriteListModel';

const schoolStore = getModule(SchoolModule);

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false
};

@Component({
	components: {
		FavouriteListProvider,
		SchoolCard,
		ButtonToggle,
		SchoolDetailProvider
	}
})
export default class DisplaySchools extends Mixins(
	VuetifyMixin,
	CurrentAthleteMixin
) {
	@Prop({ default: false }) enabled: boolean; 
	mdiFormatListBulleted = mdiFormatListBulleted;
	mdiHeart = mdiHeart;
	mdiHeartOutline = mdiHeartOutline;
	mdiSchool = mdiSchool;

	SchoolFavouriteItem=SchoolFavouriteItem;

	@Prop() schoolId: string = "";
	@Prop() changeToList: boolean = false;

	tableOptions: DataOptions = DEFAULT_OPTIONS;
	clickedList: boolean = true;
	generatedSchoolsFromFavouriteList: Array<{school: ESSchoolModel, favoriteItem: SchoolFavouriteItem}> = [];

	async created(): Promise<void> {
		schoolStore.setParentId(this.CurrentAthleteId);
	}

	get SchoolsInitialized(): boolean{
		return !schoolStore.schoolsInitialized;
	}

	get isClickedList(): boolean {
		if (this.changeToList === true) {
			this.clickedList = false;
			return this.clickedList;
		} else {
			this.clickedList = true;
			return this.clickedList;
		}
	}

	get Loading(): boolean{
		return schoolStore.schoolsLoading;
	}

	async getSchool(favouriteList: SchoolFavoriteListModel): Promise<void> {
		this.generatedSchoolsFromFavouriteList = [];

		if (this.enabled) {
			const options: QueryOptions = {
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage
			};
		
			if (this.tableOptions.sortBy.length > 0) {
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index]
						};
					})
				};
			}
			
			if (favouriteList !== null) {
				if (favouriteList.list.length !== 0) {
					const query: RepositoryQuery<ESSchoolModel> = {
						$and: [{
							$nestedPath: 'locations',
							['locations.id' as unknown as keyof ESSchoolModel]: {
								$any: favouriteList.list.map(favoriteSchoolItem => favoriteSchoolItem.locationId),
							}
						}]
					};
					await schoolStore.loadSchoolList({ query, options });
					
					this.generatedSchoolsFromFavouriteList = (schoolStore.schoolList as ESSchoolModel[])
						.map(school => ({ 
							school, 
							favoriteItem: new SchoolFavouriteItem().load({id: school.id, locationId: school.locations.id
							})
						}));
				} else {
					//does a dummy search to return nothing
					const query: RepositoryQuery<SchoolModel> = { $match: { id: 1 } };
					schoolStore.loadSchoolList({ query, options });
				}
			} else {
				const query: RepositoryQuery<SchoolModel> = { $match: { id: 1 } };
				schoolStore.loadSchoolList({ query, options });
			}
		}
		else {
			this.generatedSchoolsFromFavouriteList = ESDummySchoolList.map(school => ({ 
				school, 
				favoriteItem: new SchoolFavouriteItem().load({id: school.id, locationId: school.locations.id
				})
			}));
		}

		console.log(this.generatedSchoolsFromFavouriteList);
	}
	changeButtonToggle(
		isList: boolean,
		favouriteList: SchoolFavoriteListModel
	): void {
		this.clickedList = isList;
		if (isList === false) {
			this.getSchool(favouriteList);
			if (favouriteList === null) {
				this.$emit("clicked:fav", 0);
			} else {
				this.$emit("clicked:fav", favouriteList.list.length);
			}
		} else {
			this.$emit("clicked:list");
		}
	}

	async toggleFavoriteItem(favoriteItem: SchoolFavouriteItem, providerFavoriteToggle: (item: SchoolFavouriteItem) => Promise<void>, favouriteList: FavouriteListModel): Promise<void> {
		const isInSet = favouriteList.isInSet(favoriteItem);
		await providerFavoriteToggle(favoriteItem);
		if (isInSet){
			favouriteList.removeFromSet(favoriteItem)
		} else {
			favouriteList.addToSet(favoriteItem)
		}
		this.$emit('clicked:fav', favouriteList.list.length)
	}

	get Schools(): ESSchoolModel[] {
		return this.enabled ? schoolStore.schoolList as ESSchoolModel[] : ESDummySchoolList;
	}
	get TotalSchools(): number {
		return schoolStore.totalSchools;
	}

	get CurrentAthleteId(): string {
		return userStore.athleteId;
	}

	showCard(favoriteItem: SchoolFavouriteItem, favouriteList: FavouriteListModel): boolean {
		if (favouriteList !== null) {
			if (
				(this.clickedList == true && favouriteList.isInSet(favoriteItem)) ||
				(this.clickedList == true &&
					!favouriteList.isInSet(favoriteItem)) ||
				(this.clickedList == false && favouriteList.isInSet(favoriteItem))
			) {
				return true;
			}

			return false;
		} else {
			if (this.clickedList === true) {
				return true;
			}

			return false;
		}
	}
	DetailPageRoute(id: string): Partial<Route> {
		return {
			name: Routes.SchoolDetails,
			params: {
				athleteId: userStore.athleteId,
				schoolId: id,
				gender: userStore.athlete.gender
			}
		};
	}

}
