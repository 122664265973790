import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import { AthleteTargetedSharingApi } from '@/api/AthleteTargetedSharingApi';
import { athleteApi } from '@/api/AthleteApi';
import { PaginatedResponse, QueryOptions, RepositoryQuery } from '@/../types/interfaces';
import { ViewTrackingRecordViewModel } from '@/models/viewTrackingRecord/viewTrackingRecordViewModel';

const Mutations = {
	QUERY_SHARING_DATA: 'QUERY_SHARING_DATA',
	QUERY_SHARING_DATA_SUCCESS: 'QUERY_SHARING_DATA_SUCCESS',
	QUERY_SHARING_DATA_FAILURE: 'QUERY_SHARING_DATA_FAILURE',

	SHARE_PROFILE: 'SHARE_PROFILE',
	SHARE_PROFILE_SUCCESS: 'SHARE_PROFILE_SUCCESS',
	SHARE_PROFILE_FAILURE: 'SHARE_PROFILE_FAILURE',

	RESET_INITIALIZED_FLAG: 'RESET_INITIALIZED_FLAG',
}

const name = 'AthleteTargetedSharingStore';
if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class AthleteTargetedSharingModule extends VuexModule {

	querySharingDataInitialized: boolean = false;
	querySharingDataLoading: boolean = false;
	querySharingDataAthleteId: string | null = null;
	totalSharingRecords: number = 0;
	sharingRecords: TargetedSharingModel[] = [];
	totalViewedRecords: number = 0;
	viewedRecords: ViewTrackingRecordViewModel[] = [];
	totalUnseenRecords: number = 0;
	unseenRecords: TargetedSharingModel[] = [];
	@Action({
		rawError: true,
	}) async querySharingData({ athleteId, query, options }: { athleteId: string, query: RepositoryQuery<TargetedSharingModel>, options: QueryOptions  }): Promise<void> {
		this.context.commit(Mutations.QUERY_SHARING_DATA)
		try {
			const api = new AthleteTargetedSharingApi(athleteId);
			const shareData = await api.queryAll(query,options)
			const unseenData = await api.queryAll({$match:{seen: false}},{ limitPerPage: 5 });
			const viewedData = await athleteApi.queryViewRecords(athleteId, {},{ limitPerPage: 15 });
			this.context.commit(Mutations.QUERY_SHARING_DATA_SUCCESS, { shareData, unseenData, viewedData } );
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_SHARING_DATA_FAILURE, e);
		}
	}
	@Mutation [Mutations.QUERY_SHARING_DATA](): void {
		this.querySharingDataLoading = true;
	}

	@Mutation [Mutations.QUERY_SHARING_DATA_SUCCESS]({
		athleteId,
		shareData,
		viewedData,
		unseenData,
	}: {
		athleteId: string,
		shareData: PaginatedResponse<TargetedSharingModel>,
		viewedData: PaginatedResponse<ViewTrackingRecordViewModel>,
		unseenData: PaginatedResponse<TargetedSharingModel>,
	} ): void {
		this.querySharingDataAthleteId = athleteId;
		this.totalSharingRecords = shareData.total;
		this.sharingRecords = shareData.docs;
		this.totalViewedRecords = viewedData.total;
		this.viewedRecords = viewedData.docs;
		this.totalUnseenRecords = unseenData.total;
		this.unseenRecords = unseenData.docs;
		this.querySharingDataLoading = false;
		this.querySharingDataInitialized = true;
	}

	@Mutation [Mutations.QUERY_SHARING_DATA_FAILURE](error: any): void {
		this.querySharingDataLoading = false;
	}

	shareProfileLoading: boolean = false;
	@Action({
		rawError: true,
	}) async shareProfile({ athleteId, emails, message }: { athleteId: string, emails: string[], message: string }): Promise<void> {
		this.context.commit(Mutations.SHARE_PROFILE)
		try {
			const api = new AthleteTargetedSharingApi(athleteId);
			await api.shareToEmails({
				emails: emails.map(email => email.trim()),
				message
			});
			this.context.commit(Mutations.SHARE_PROFILE_SUCCESS);
			this.context.dispatch(
				'NotificationStore/pushLocalNotificationMessage',
				`Your profile has been shared successfully!`,
				{ root: true }
			);
		} catch (e) {
			this.context.commit(Mutations.SHARE_PROFILE_FAILURE, e);
			this.context.dispatch(
				'NotificationStore/pushErrorNotificationMessage',
				"Something went wrong, please try again later",
				{ root: true }
			);
		}
	}
	@Mutation [Mutations.SHARE_PROFILE](): void {
		this.shareProfileLoading = true;
	}
	@Mutation [Mutations.SHARE_PROFILE_SUCCESS](): void {
		this.shareProfileLoading = false;
	}
	@Mutation [Mutations.SHARE_PROFILE_FAILURE](): void {
		this.shareProfileLoading = false;
	}
}