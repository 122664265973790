import { DomainObject } from "../DomainObject";
import { FrontEndModel } from "../FrontEndModel";
import { HinderResource } from "@/../types/constants/resources";

export class FavouriteListModel<T extends DomainObject = DomainObject> extends FrontEndModel {
	parentId: string;
	resource: HinderResource;
	list: T[];

	isInSet(item: T): boolean {
		return this.getIndex(item) > -1;
	}

	getIndex(item: T): number {
		return this.list && this.list.length > 0 ? this.list.findIndex(favorited => {
			let indentical = true;
			for (const key of Object.keys(favorited)){
				if (favorited[key] !== item[key]) {
					indentical = false;
				}
			}
			return indentical;
		}) : -1;
	}

	addToSet(item: T): void {
		if (!this.isInSet(item)) {
			this.list.push(item);
		}
	}

	removeFromSet(item: T): void {
		if (this.isInSet(item)) {
			this.list.splice(this.getIndex(item), 1);
		}
	}
}
