import { ComparativeDataPageState } from '@/models/pageStates/ComparativeDataPageState';
import { comparativeDataV2Store, comparativeDataV2SampleDataStore } from '@/store'
import { Component, Vue } from 'vue-property-decorator';

@Component
export class ComparativeSampleDataMixin extends Vue {
	showManageSampleDataDialog: boolean = false;
	localForagePersistFields: Array<string | [string, any]> = [['useSampleData', false]]
	get useSampleData(): boolean {
		return comparativeDataV2Store.useSampleData;
	}
	set useSampleData(useSampleData: boolean) {
		this.setUseSampleData(useSampleData);
	}
	setUseSampleData(sampleData: boolean): void {
		comparativeDataV2Store.setUseSampleData(sampleData);
		if (this.useSampleData === true) {
			comparativeDataV2SampleDataStore.loadSpiderChart();
		}
	}
	get SpiderChartState(): ComparativeDataPageState {
		return comparativeDataV2Store.spiderChartState;
	}
}