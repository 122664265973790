var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chart-container pt-12 pr-8"},[_c('table',{ref:"chartTable",staticClass:"width-100"},[(_vm.ShowCompareBar)?_c('tr',[(_vm.UseSideLabel)?_c('td',{staticClass:"label compare px-2",style:({
				color: _vm.CompareColor,
				'line-height': `${_vm.CompareBarHeight}px`
			})},[_vm._v(" You ")]):_vm._e(),_c('td',{staticClass:"progress-td compare"},[_c('ProgressBar',{attrs:{"height":_vm.CompareBarHeight,"color":_vm.CompareColor,"progress":_vm.ComparePercentage,"tile-left":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.UseAvatar)?[_c('CircledAvatar',{staticClass:"compare-pin",style:(_vm.AvatarStyle),attrs:{"color":_vm.CompareColor,"border-size":"3","src":_vm.pictureUrl,"size":_vm.AvatarSize}})]:_c('LocationPin',{staticClass:"compare-pin location-pin",attrs:{"pictureUrl":_vm.pictureUrl,"color":_vm.CompareColor}}),_c('div',{staticClass:"compare-vertical-line",style:({'height': _vm.CompareLineHeight})})]},proxy:true},{key:"append-outer",fn:function(){return [_c('div',{staticClass:"compare-unit-append baColorPrimaryText--text pl-2"},[_vm._v(" "+_vm._s(_vm.CompareLabel)+" ")])]},proxy:true}],null,false,1672151824)})],1)]):_vm._e(),_vm._t("prepend-row"),_vm._l((_vm.DataGroups),function(group,index){return _c('tr',{key:index},[(_vm.UseSideLabel)?_c('td',{staticClass:"label px-2"},[_c('v-fade-transition',{attrs:{"group":""}},_vm._l((group.data),function(item,itemIndex){return _c('div',{key:item.label,staticClass:"mt-1",style:({
						color: _vm.LabelColor,
						height: `${_vm.CompareBarHeight}px`,
						'font-size': `${itemIndex === 0 ? _vm.ValueLabelFontSize : _vm.AltValueLabelFontSize}px`,
					})},[(itemIndex === 0)?_c('span',[_vm._v(" "+_vm._s(group.label)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")])}),0)],1):_vm._e(),_c('td',{staticClass:"progress-td"},[(!_vm.UseSideLabel)?_c('div',{staticClass:"label text-left pl-1 baColorPrimaryText--text"},[_vm._v(" "+_vm._s(group.label)+" ")]):_vm._e(),_c('v-scroll-x-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((group.data),function(item,itemIndex){return _c('div',{key:`${item.label}_${itemIndex}`},[_c('ProgressBar',{style:({ 'margin-top': '5px' }),attrs:{"height":_vm.CompareBarHeight,"use-background":"","color":item.color,"border-color":_vm.MetricColor,"hide-left-border":"","progress":item.progress,"tile-left":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{staticClass:"unit-append",class:{'alt-size': itemIndex !== 0},style:({ color: _vm.LabelColor, 'font-size': `${itemIndex === 0 ? _vm.ValueLabelFontSize : _vm.AltValueLabelFontSize}px`})},[_vm._v(" "+_vm._s(_vm.formatValue(item.value))+" ")])]},proxy:true}],null,true)}),(!_vm.UseSideLabel)?_c('div',{staticClass:"label text-left pl-1",style:({ color: _vm.LabelColor})},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()],1)}),0)],1)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }