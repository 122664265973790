
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, MyTeamsMixin, CurrentAthleteMixin, AuthMixin, FeatureFlagMixin } from "@/mixins";
import { mdiInformationOutline } from '@mdi/js';
import Page from "@/views/Page.vue";
import * as Routes from "@/../types/constants/web_client_user.routes";

import RecruitingProfileInfoProvider from "@/components/hoc/RecruitingProfileInfoProvider.vue";
import RecruitingProfileCard from "@/components/profile/recruiting/RecruitingProfileCard.vue";
import IdCrossSvg from "@/components/svg/IdCrossSvg.vue";
import IdCrossSvgFail from "@/components/svg/IdCrossSvgFail.vue";
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import SharingMetric from "@/components/sharing/SharingMetric.vue";
import SettingsAlertCard from "@/components/ui/SettingsAlertCard.vue";

@Component({
	components: {
		Page,
		RecruitingProfileCard,		
		IdCrossSvg,
		IdCrossSvgFail,
		RecruitingProfileInfoProvider,
		SharingInfoProvider,
		SharingMetric,
		SettingsAlertCard,
	},
	
})
export default class AthleteRecruitingProfileView extends Mixins(
	AuthMixin,
	MyTeamsMixin,
	CurrentAthleteMixin,
	VuetifyMixin,
	AppHostnameMixin,
	FeatureFlagMixin,
) {
	@Prop({ type: String, default: null }) athleteId: string | null;
	@Prop({ type: String, default: null }) sharingUrlId: string | null;
	mdiInformationOutline = mdiInformationOutline;

	get AthleteId(): string | null{
		if(this.AthleteAppActive){
			return this.CurrentAthleteId;
		}
		return this.athleteId;
	}

	get IsMyAthleteProfile(): boolean{
		return this.AthleteAppActive && this.sharingUrlId === null;
	}

	get IsProfileEditable(): boolean {
		return this.AthleteAppActive || this.AdminAppActive || this.ClubAdminAppActive;
	}

	navigateToEdit(step?: string): void {
		// Open edit page
		this.$router.push({
			path: '/settings',
			params: {
				...this.$route.params,
				currentStep: step,
			},
			query: { u: this.CurrentUserProfileId }
		});
	}

	gotoComparative(step?: string): void {
		// Open edit page
		this.$router.push({
			path: '/settings',
			params: {
				...this.$route.params,
				currentStep: step,
			},
			query: { u: this.CurrentUserProfileId }
		});
	}

	gotoCoachDashboard(): void {
		this.$router.push({
			name: Routes.CoachDashboard
		})
	}

	viewSharingDetails(): void{
		this.$router.push({
			name: Routes.AthleteSharingSummary,
			params:{
				...this.$route.params,
			}
		}).catch(() => {});
	}
}
