import { render, staticRenderFns } from "./SharingHistory.vue?vue&type=template&id=71da4621&scoped=true&"
import script from "./SharingHistory.vue?vue&type=script&lang=ts&"
export * from "./SharingHistory.vue?vue&type=script&lang=ts&"
import style0 from "./SharingHistory.vue?vue&type=style&index=0&id=71da4621&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71da4621",
  null
  
)

export default component.exports