
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { mdiChevronRight } from '@mdi/js';
import { VuetifyMixin } from '../../../mixins';
@Component
export default class SettingsNavItem extends Mixins(VuetifyMixin){
	mdiChevronRight = mdiChevronRight;
	@Prop({ required: true }) to: Partial<Route>;
	get IsActive(): boolean{
		return this.$route.name.includes(this.to.name);
	}

	get Color(): string{
		if(this.IsActive){
			return this.getColor('baColorVibrantBlueText');
		}
		return this.getColor('baColorSecondayText');
	}

	get Style(): Record<string, any>{
		if(this.IsActive){
			return {
				'font-weight': 'bold',
				'color': this.Color,
			};
		}
		return {
			'font-weight': '300',
		};
	}
}
