
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SpqDataModel } from '../../models/athlete/SpqDataModel';

@Component({
	render(h) {
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class SpqDataInfoProvider extends Vue {
	@Prop({ default: 'div' }) private as: string;
	@Prop({ required: true }) private spqData: SpqDataModel;


	@Watch('spqData')
	async loadLatestAthleteSpqData(): Promise<void>{
		if (!this.spqData) {
			console.warn("Assessment Data not found");
			return;
		}
	}

	created(): void{
		//this.loadSpqData();
	}

	get SlotProps(): Record<string, any>{
		return {
			athleteId: this.AthleteId,
			//TestDate: this.TestDate,
			// Title: this.Title,
			// IconComponentName: this.IconComponentName,
			// MetricData: this.MetricData,
			// ExpandedMetricData: this.ExpandedMetricData,
			// ComparativeDataReady: this.ComparativeDataReady,
			// ComparativeDataLoading: this.ComparativeDataLoading,
			// ComparativeBarChartStats: this.ComparativeBarChartStats,
			// PerformanceLevel: this.PerformanceLevel,
			// UseDecimal: this.UseDecimal,
			// Over18: this.Over18,
		};
	}

	get AthleteId(): string | undefined{
		if (!this.spqData) return undefined;
		return this.spqData.athleteId;
	}
	// get TestDate(): string{
	// 	if(!this.spqData) return '';
	// 	return formatDatePretty(this.spqData.assessmentDate);
	// }

	// get Title(): string{
	// 	return title(this.metric);
	// }


}
