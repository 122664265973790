
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, AuthMixin, AthleteMetricsMixin } from '@/mixins';
import ComparativeDataProvider from '@/components/hoc/ComparativeDataProvider.vue';
import MetricWithIcon from '@/components/profile/athlete/MetricWithIcon.vue';
import { AthleteMetricValues, AthleteMetric } from '@/../types/enums';
import { comparativeDataStore } from '@/store';
import { performanceCategoryToLabel } from '@/pipes';

@Component({
	components: {
		ComparativeDataProvider,
		MetricWithIcon,
	}
})
export default class AthleteComparativeDataPerformance extends Mixins(AuthMixin, AthleteMetricsMixin, VuetifyMixin){
	@Prop({type: Boolean, default: false}) useSampleData: boolean;
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get TabColors(): string[]{
		return this.AthleteMetrics.map(metric => {
			return this.getColor(
				this.$route.params.metric === metric ? this.getMetricColor(metric) : 'baColorGray3'
			)
		});
	}
	get Metric(): AthleteMetric{
		return this.$route.params.metric as AthleteMetric;
	}
	updateMetric(metric: AthleteMetric): void{
		this.$router.push({
			...this.$route,
			params:{
				...this.$route.params,
				metric,
			}
		});
	}
	get SliderColor(): string{
		return this.getMetricColor(this.Metric);
	}
	get MetricPerformanceCategory(): string | null{
		if(comparativeDataStore.ComparisonReady){
			return performanceCategoryToLabel(comparativeDataStore.Comparison[this.Metric]);
		}
		return null;
	}

	get UseMobile(): boolean{
		return this.$vuetify.breakpoint.mdAndDown;
	}
}
