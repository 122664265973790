
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { mdiChevronLeft, mdiCheck, mdiClose, mdiAlert } from '@mdi/js';

import * as Routes from '../../types/constants/web_client_user.routes';
import Page from './Page.vue';
import Alert from '../components/ui/Alert.vue';
import ClipboardSvg from '../components/svg/ClipboardSvg.vue';
import SurveySvg from '../components/svg/SurveySvg.vue';
import CodeInput from '../components/forms/CodeInput.vue';
import MultiStepForm from '../components/forms/MultiStepForm.vue';
import AssociateDiagram from '../components/ui/AssociateDiagram.vue';
import TeamMiniCard from '../components/ui/TeamMiniCard.vue';
import { MultiStepFormMixin } from '../mixins/MultiStepFormMixin';
import { AuthMixin, FormRulesMixin, DebounceMixin } from '../mixins';
import { scoutingReportApi } from '../api/ScoutingReportApi';
import { athleteProfile, userStore } from '../store';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';

@Component({
	components: {
		Alert,
		AssociateDiagram,
		CodeInput,
		ClipboardSvg,
		MultiStepForm,
		TeamMiniCard,
		Page,
		SurveySvg,
	},
})
export default class ClaimScoutingReportForm extends Mixins(DebounceMixin, MultiStepFormMixin, FormRulesMixin, AuthMixin){
	mdiAlert = mdiAlert;
	mdiCheck = mdiCheck;
	mdiClose = mdiClose;
	mdiChevronLeft = mdiChevronLeft;

	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	formComplete: boolean = false;
	loading: boolean = false;
	get Loading(): boolean{
		return this.loading;
	}
	steps = 2;
	currentStep = 1;

	get CurrentStepValid(): boolean {
		const step = this.formValue[this.CurrentStepKey];
		if(this.currentStep === 1){
			return step.valid && this.codeValid;
		}
		return step ? step.valid : true;
	}

	get Progress(): number{
		switch(this.currentStep){
		case 1: return 50;
		case 2: return 80;
		default: return 100;
		}
	}

	get UserAthleteInfo(): AthleteProfileModel{
		return userStore.athlete;
	}

	defaultForm = (): Record<string,any> => ({
		step1: {
			valid: false,
			scoutingReportCode: "",
		},
	});
	formValue = this.defaultForm();
	updateScoutingReportCode(scoutingReportCode: string): void{
		this.formValue.step1.scoutingReportCode = scoutingReportCode.toUpperCase();
	}

	private nextIfValid(){
		if(this.CurrentStepValid){
			this.next();
		}
	}

	codeValid: boolean = true;
	codeValidInitialized: boolean = false;
	codeValidLoading: boolean = false;
	token: {id: string, name: string} | null = null;
	get ScoutingReportCodeErrorMessages(): string[]{
		if(this.codeValid || this.codeValidLoading === true){
			return [];
		}
		return ["Scouting Report Code invalid"];
	}
	@Watch('formValue.step1.scoutingReportCode')
	private async validateCode(code: string){
		this.formError = "";
		if(code.length !== 9) return;
		this.codeValid = false;
		this.codeValidLoading = true;
		this.codeValidInitialized = true;
		this.debounceCallback('validateCode', () => {
			scoutingReportApi.verifyClaimCode({ code }).then(({valid}: { valid: boolean }) => {
				this.codeValid = valid;
			}).catch(e => {
				console.error("Failed to validate team code", e);
				this.codeValid = false;
			}).finally(() => {
				this.codeValidLoading = false;
			})
		}, 2000);
	}

	formError: string = "";
	async finish(): Promise<void>{
		this.formError = "";
		try{
			await scoutingReportApi.associateFromClaimCode({
				code: this.formValue.step1.scoutingReportCode,
				athleteId: this.UserAthleteInfo.id,
			});
			this.formComplete = true;
			// Reload Scouting Report data
			await athleteProfile.getAthleteProfile(this.UserAthleteInfo.id);
		}catch(e){
			console.error("Failed to claim scouting report", e);
			this.formError = "Failed to add scouting report data, please double check your Code and try again later.";
		}
	}
	cancel(): void{
		this.goToDashboard();
	}

	private goToVerified(){
		this.$router.push({
			name: Routes.AthleteScoutingReport,
			params:{
				...this.$route.params,
			}
		});
	}
	goToDashboard(): void{
		this.$router.push({
			name: Routes.AthleteProfile
		});
	}
}
