
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';

@Component
export default class RyzerScore extends Mixins(VuetifyMixin){
	@Prop({ default: 'unknown' }) level: string;
	@Prop({ default: 30 }) size: number;
	@Prop({ default: 0 }) score: number;
	@Prop({ default: 'red' }) color: string; // color to use if no level is defined. ignored if level is defined
	@Prop({ default: '#444445' }) titleColor: string;
	@Prop({ default: '#FFFFFF' }) scoreColor: string;

	get isElite() : boolean {
		return this.level.toUpperCase().startsWith('E');
	}
	get isCompetitive() : boolean {
		return this.level.toUpperCase().startsWith('C');
	}
	get isNovice() : boolean {
		return ((this.level.toUpperCase().startsWith('N')) || (this.score === 0));
	}

	get scoreText() : string {
		if( this.isNovice ) return 'N';
		return this.score.toString();
	}

	get circleColor() : string {
		if( this.isElite ) return '#0e7a3d';
		if( this.isCompetitive ) return '#3cb551';
		if( this.isNovice ) return '#e1ad27';
		return this.color;
	}

	get circleSize() : number {
		return this.size * 5;
	}
	get circleX() : number {
		return 77.74;
	}
	get circleY() : number {
		// if the level is defined, there will be text above the circle
		if( this.isElite || this.isCompetitive || this.isNovice ) return 85.71;

		// if no level is defined, move the circle up
		return 60;
	}

	get scoreSize() {
		return this.size * 2;
	}
	get scoreX() : number {
		return this.circleX;
	}
	get scoreY() : number {
		return this.circleY + 4.0;
	}

}
