import { AnalyticsGender, Gender } from '../../../../libs/ba-types/src';
type GetGenderTextPluralOpts = {
	/** Use Mens/Womens instead of Boys/Girls */
	adult?: boolean,
}
export function getGenderTextPlural(value: Gender | AnalyticsGender, opts: GetGenderTextPluralOpts = {}): string{
	const { adult = false } = opts;
	if(value === "M" || value === Gender.Male) return adult === true ? "Men" : "Boys";
	if(value === "F" || value === Gender.Female) return adult === true ? "Women" : "Girls";
	return null;
}
