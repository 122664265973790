
import { Component, Mixins } from 'vue-property-decorator';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import { AthleteMetricValues, AthleteMetric } from '../../types/enums';
import * as Routes from '../../types/constants/web_client_user.routes';
import { VuetifyMixin } from '../mixins';
import Page from '../views/Page.vue';
import ProgressBar from '../components/charts/ProgressBar.vue';
import ExpandingCard from '../components/ui/ExpandingCard.vue';
import MetricChart from '../components/profile/athlete/MetricChart.vue';

import RulerSvg from '../components/svg/RulerSvg.vue';
import SpeedometerSvg from '../components/svg/SpeedometerSvg.vue';
import StopwatchSvg from '../components/svg/StopwatchSvg.vue';
import LightningSvg from '../components/svg/LightningSvg.vue';
import AgilitySvg from '../components/svg/AgilitySvg.vue';
import HeartVitalSvg from '../components/svg/HeartVitalSvg.vue';
import { title } from '../pipes';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';
import { athleteProfile, comparativeDataStore } from '../store';

@Component({
	components: {
		Page,
		ExpandingCard,
		MetricChart,
		ProgressBar,

		RulerSvg,
		SpeedometerSvg,
		StopwatchSvg,
		LightningSvg,
		AgilitySvg,
		HeartVitalSvg,
	},
	beforeRouteEnter(_, __, next){
		return next((vm: AthleteComparativeData) => {
			if(!vm.AssessmentData){
				vm.$router.push({
					name: Routes.AthleteProfile,
				});
			}
		});
	},
	beforeRouteUpdate(_, __, next){
		if(!this.AssessmentData){
			this.$router.push({
				name: Routes.AthleteProfile,
			});
		}
		next();
	}
})
export default class AthleteComparativeData extends Mixins(VuetifyMixin){
	title = title;
	mdiChevronRight = mdiChevronRight;
	mdiChevronLeft = mdiChevronLeft;
	selectedTab: number = 0;

	get TabColors(): string[]{
		return this.AthleteMetrics.map(metric => {
			return this.getColor(
				this.$route.params.metric === metric ? 'baColorPrimaryBlue' : 'baColorGray3'
			)
		});
	}

	get Loading(): boolean{
		return athleteProfile.IsLoading || comparativeDataStore.comparativeDataLoading;
	}

	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}

	get AssessmentDataIsReady(): boolean{
		return athleteProfile.AssessmentDataIsReady;
	}
	get AssessmentData(): AthleteAssessmentDataModel{
		return athleteProfile.athleteAssessmentData;
	}

	get CurrentMetric(): string{
		return  this.$route.params.metric;
	}

	MetricIcons: Record<string, any> = {
		[AthleteMetric.Acceleration]: 'SpeedometerSvg',
		[AthleteMetric.Speed]: 'StopwatchSvg',
		[AthleteMetric.Power]: 'LightningSvg',
		[AthleteMetric.Agility]: 'AgilitySvg',
		[AthleteMetric.Recovery]: 'HeartVitalSvg',
	};


	toAthleteProfile(): void{
		this.$router.push({
			name: Routes.AthleteProfile,
		})
	}
}
