
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiCloseCircle, mdiPlus, mdiChevronDown, mdiInformationOutline } from '@mdi/js';
import { title } from '@/pipes';
import AccessControlForm from '@/components/profile/settings/AccessControlForm.vue';
import AthleteAccessControlUserRow from './AthleteAccessControlUserRow.vue';
import PermissionsSelector from '@/components/forms/PermissionsSelector.vue';
import UserAclInfoProvider from '@/components/hoc/UserAclInfoProvider.vue';
import { FormRulesMixin, AuthMixin, FeatureFlagMixin } from '@/mixins';
import { UserAcl, AthleteUserRelationship, AthleteProfileRole, AthleteRelationship } from '@/../types/interfaces';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { AthleteProfileRoleName, AthleteProfileRelationshipName } from '@/../types/enums';
import { getDaysBetween } from '@/helpers/date';

@Component({
	components: {
		AccessControlForm,
		AthleteAccessControlUserRow,
		PermissionsSelector,
		UserAclInfoProvider
	},
})
export default class AthleteAccessControlForm extends Mixins(AuthMixin, FormRulesMixin, FeatureFlagMixin){
	title = title;
	mdiInformationOutline = mdiInformationOutline;
	mdiCloseCircle = mdiCloseCircle;
	mdiChevronDown = mdiChevronDown;
	mdiPlus = mdiPlus;

	temporaryUsers: AthleteUserRelationship[] = [];

	@Prop({ required: true }) value: AthleteProfileModel;
	input(value: AthleteProfileModel): void{
		this.$emit('input', value);
	}


	get ReadOnlyRole(): AthleteProfileRole{
		return AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.ReadOnly);
	}
	get ParentRelationship(): AthleteRelationship{
		return AthleteProfileModel.Relationships.find(relationship => relationship.name === AthleteProfileRelationshipName.Parent);
	}

	get Roles(): AthleteProfileRole[]{
		return AthleteProfileModel.Roles;
	}
	get AvailableRelationships(): AthleteRelationship[]{
		if(this.AthleteUserExists){
			return AthleteProfileModel.Relationships.filter(rel => rel.name !== AthleteProfileRelationshipName.Athlete);
		}
		return AthleteProfileModel.Relationships;
	}
	get AthleteFullName(): string{
		if(!this.value) return '';
		return this.value.FullName;
	}
	get AthleteEmailAddress(): string{
		if(!this.value?.email) return '';
		return this.value.email;
	}
	get AthleteFirstName(): string{
		if(!this.value) return '';
		return this.value.firstName;
	}
	get AthleteUserExists(): boolean{
		return this.AthleteUser !== null;
	}
	get AthleteUser(): UserAcl | null{
		if(!this.value) return null;
		const athleteRelation = this.value.userRelationships.find(rel => rel.relationship.name === AthleteProfileRelationshipName.Athlete);
		if(athleteRelation === undefined) return null;
		const athleteUser = this.value.users.find(u => u.id === athleteRelation.userId)
		if(athleteUser === undefined) return null;
		return athleteUser;
	}
	get AthleteAgeYears(): number{
		return getDaysBetween(this.value.birthDate,new Date()).days/365;
	}
	get AthleteInvitationPending(): boolean{
		if(!this.AthleteUserExists) return false;
		return this.AthleteUser.id.startsWith('tmp_')
	}
	get OtherUsers(): UserAcl[]{
		if(!this.value) return [];
		const [ athleteUserId ] = this.value.userRelationships
			.filter(rel => rel.relationship.name === AthleteProfileRelationshipName.Athlete)
			.map(rel => rel.userId);
		return this.value.users.filter(u => athleteUserId !== u.id);
	}
	
	showInviteDialog: boolean = false;
	addUserValid: boolean = false;
	addUserForm: AthleteUserRelationship = {
		role: AthleteProfileModel.Roles.find(r => r.name === AthleteProfileRoleName.Owner),
		relationship: undefined,
		inviteEmail: "",
		userId: "",
	};
	showAddUser(): void{
		this.addUserForm = {
			role: AthleteProfileModel.Roles.find(r => r.name === AthleteProfileRoleName.Owner),
			relationship: undefined,
			inviteEmail: "",
			inviteName: "",
			userId: `tmp_${~~(Math.random() * 10000)}`,
		};
		this.showInviteDialog = true;
	}
	inviteAthlete(): void{
		this.addUserForm = {
			role: AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.Owner),
			relationship: AthleteProfileModel.Relationships.find(rel => rel.name === AthleteProfileRelationshipName.Athlete),
			userId: `tmp_${~~(Math.random() * 10000)}`,
			inviteName: this.AthleteFullName,
			inviteEmail: this.AthleteEmailAddress,
		};
		this.showInviteDialog = true;
	}
	get ShowAthleteMessage(): boolean{
		if(this.addUserForm.relationship && this.addUserForm.relationship.name === AthleteProfileRelationshipName.Athlete){
			return true;
		}
		return false;
	}
	get ShowOwnerWarning(): boolean{
		if(this.addUserForm.role && this.addUserForm.role.name === AthleteProfileRoleName.Owner){
			return true;
		}
		return false;
	}
	addTempUser(): void{
		const tempUserRelation: AthleteUserRelationship = {
			...this.addUserForm,
			role: AthleteProfileModel.Roles.find(role => role.name === AthleteProfileRoleName.Owner),
		};
		this.value.setUser(this.addUserForm.userId, tempUserRelation);
		this.showInviteDialog = false;
		this.onSave();
	}

	removeAthlete(): void{
		this.value.removeUser(this.AthleteUser.id);
		this.onSave();
	}
	removeUser(): void{
		this.onSave();
	}

	onSave(): void{
		this.$emit('save', this.value);
	}
	onCancel(): void{
		this.$emit('cancel');
	}
}
