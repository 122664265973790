
import { Component, Mixins, Prop } from 'vue-property-decorator';
import * as Routes from '../../../../types/constants/web_client_user.routes';
import { AthleteMetric, AthleteMetricValues } from '../../../../types/enums';
import { VuetifyMixin } from '../../../mixins';
import { AthleteProfileModel } from '../../../models/athlete/AthleteProfileModel';
import { SelfAssessmentModel } from '../../../models/athlete/SelfAssessmentModel';

import AthleteSelfAssessmentStatCard from './AthleteSelfAssessmentStatCard.vue';
import { formatDatePretty } from '../../../helpers';

@Component({
	components:{
		AthleteSelfAssessmentStatCard,
	}
})
export default class AthleteSelfAssessmentSummary extends Mixins(VuetifyMixin){

	@Prop({ default: null }) athlete: AthleteProfileModel | null;
	@Prop({ default: null }) selfAssessment: SelfAssessmentModel | null;
	@Prop({ default: true }) addAssessmentBtnVisible: boolean;
	@Prop({ default: true }) viewDetailsBtnVisible: boolean;
	@Prop({ default: true }) loadData: boolean;

	viewDetails(): void{
		this.$router.push({
			name: Routes.AthleteSelfReportSummary,
			params:{
				athleteId: this.athlete.id,
			}
		});
	}

	addAssessment(): void{
		this.$router.push({
			name: Routes.AthleteSelfAssessmentIntro,
			params:{
				...this.$route.params,
			}
		});
	}

	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get SelfAssessmentData(): SelfAssessmentModel | null{
		return this.selfAssessment;
	}
	get HasSelfAssessment(): boolean{
		return this.selfAssessment !== null && this.selfAssessment !== undefined && this.selfAssessment.IsStarted;
	}

	get FullName(): string{
		return `${this.AthleteProfile.firstName} ${this.AthleteProfile.lastName}`;
	}

	get AthleteProfile(): AthleteProfileModel{
		return this.athlete;
	}

	get LastAssessedDate(): string | undefined{
		if(!this.selfAssessment) return undefined;
		return formatDatePretty(this.selfAssessment.startDate);
	}
}
