
import { Component, Mixins } from 'vue-property-decorator';
import { mdiInformationOutline } from '@mdi/js';
import { ComparativeSampleDataMixin } from '@/mixins/ComparativeSampleDataMixin';

@Component
export default class ComparativeDataUsingSampleDataAlert extends Mixins(
	ComparativeSampleDataMixin,
) {
	mdiInformationOutline = mdiInformationOutline;
}
