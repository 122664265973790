
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { mdiChevronLeft, mdiPlus } from '@mdi/js';

import Page from './Page.vue';
import MultiStepForm from '@/components/forms/MultiStepForm.vue';
import PaymentMethodForm from '@/components/payments/PaymentMethodForm.vue';
import MyAthleteProfilesProvider from '@/components/hoc/MyAthleteProfilesProvider.vue';
import PaymentMethodInfoProvider from '@/components/hoc/payments/PaymentMethodInfoProvider.vue';
import PaymentMethod from '@/components/payments/PaymentMethod.vue';
import CheckboxCard from '@/components/forms/CheckboxCard.vue';
import { MultiStepFormMixin } from '@/mixins/MultiStepFormMixin';
import { CheckoutIntentModel } from '@/models/checkoutIntent/CheckoutIntentModel';
import { formatPrice } from '@/pipes';
import { CustomerApi } from '@/api/CustomerApi';
import { CurrentAthleteMixin, DebounceMixin, AuthMixin } from '@/mixins';
import { LineItemModel } from '@/models/checkoutIntent/LineItemModel';
import { AthleteLineItemMeta } from '@/models/checkoutIntent/AthleteLineItemMeta';
import { notificationStore } from '@/store';
import { IntentStatus } from '@/../types/enums/intent-status';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { PaymentMethodFormValue } from '@/../types/interfaces';
import PaymentCardSvg from '@/components/svg/PaymentCardSvg.vue';
import { ConfirmCardPaymentData } from '@stripe/stripe-js';
import { PaymentMethodModel } from '@/models/stripe/PaymentMethodModel';
import { formatDatePretty } from '@/helpers';
import { country } from '@/pipes';
import { TaxLineItemModel } from '@/models/checkoutIntent/TaxLineItemModel';
import { logGAEvent } from '@/plugins/firebase';
import { PageStates, PageState } from '@/models/PageState';

@Component({
	components: {
		CheckboxCard,
		MultiStepForm,
		Page,
		PaymentMethodForm,
		PaymentMethod,
		PaymentMethodInfoProvider,
		PaymentCardSvg,
		MyAthleteProfilesProvider,
	},
})
export default class AthleteCheckoutPage extends Mixins(AuthMixin, CurrentAthleteMixin, DebounceMixin, MultiStepFormMixin){
	formatPrice = formatPrice;
	mdiChevronLeft = mdiChevronLeft;
	mdiPlus = mdiPlus;

	@Prop({ default: null }) checkoutAction: "redirect" | "cancel" | "complete" | null;
	@Prop({ default: null }) checkoutIntentId: string | null;

	/**
	 * True if any one of the LineItems are a Subscription ProductId.
	 */
	get IsSubscriptionProduct(): boolean{
		const subscription = this.LineItems.find(item => {
			return item.interval === 'year';
		});
		return subscription !== undefined;
	}
	get PageTitle(): string{
		if(!this.checkoutIntent) return "";
		if(!this.checkoutIntent.title) return "Checkout";
		return this.checkoutIntent.title;
	}

	checkoutIntent: CheckoutIntentModel<AthleteLineItemMeta> | null = null;
	get checkoutIntentInitialized(): boolean{
		return !this.pageState.IsInitial;
	}
	get checkoutIntentLoading(): boolean{
		return this.pageState.IsLoading;
	}
	pageState: PageState = new PageState("Initial");
	get TransactionMessage(): string{
		if(this.checkoutAction === "cancel"){
			return "Transaction Cancelled";
		}
		return "Transaction Complete";
	}
	created(): void{
		console.log('checkoutAction', this.checkoutAction);
		if(this.checkoutAction === "complete"){
			this.pageState = new PageState('Ready');
		}else if(this.checkoutAction === "cancel"){
			this.pageState = new PageState('Ready');
		}else if(this.checkoutIntentId !== null && (this.checkoutAction === null || this.checkoutAction === "redirect")){
			this.redirectToCheckout();
		}else if(this.checkoutIntentId !== null){
			this.loadCheckoutIntent();
		}else{
			this.pageState = new PageState('NotFound');
		}
	}
	async loadCheckoutIntent(): Promise<void>{
		this.pageState = new PageState("Loading");
		try{
			const checkoutIntent = await CustomerApi.findCheckoutIntent('athlete', this.CurrentAthleteId, this.checkoutIntentId);
			this.setFormCheckoutIntent(checkoutIntent);
			logGAEvent("checkout_progress");
			this.pageState = new PageState("Ready");
		}catch(e){
			this.pageState = PageState.getPageState(e);
		}
	}
	async redirectToCheckout(): Promise<void>{
		this.pageState = new PageState("Loading");
		try{
			const checkoutIntent = await CustomerApi.findCheckoutIntent('athlete', this.CurrentAthleteId, this.checkoutIntentId);
			/** Complete checkout on Stripe's checkout page */
			window.location.href = checkoutIntent.stripeCheckoutUrl;
		}catch(e){
			this.pageState = PageState.getPageState(e);
		}
	}

	/**
	 * Only show one of each line item in the summary. Quantities are ignored here, they will be on the review purchase page
	 */
	get UniqueLineItemsOnly(): LineItemModel<AthleteLineItemMeta>[]{
		if(this.checkoutIntent === null) return [];
		return this.checkoutIntent.lineItems.reduce((unique: LineItemModel<AthleteLineItemMeta>[], nextItem) => {
			const item = unique.find(item => item.stripeProductId === nextItem.stripeProductId);
			if(item === undefined) unique.push(nextItem);
			return unique;
		}, []);
	}

	get LineItems(): LineItemModel<AthleteLineItemMeta>[]{
		if(this.checkoutIntent === null) return [];
		return this.checkoutIntent.lineItems;
	}
	
	get TaxLineItems(): TaxLineItemModel[]{
		if(this.checkoutIntent === null) return [];
		return this.checkoutIntent.TaxesAndFees;
	}

	get ComputedTotal(): number{
		return this.checkoutIntent.ComputedSubTotal*1.13;
	}

	getItemFormattedPrice(item: LineItemModel): string{
		const priceFormatted = formatPrice(item.amount, item.currency);
		if(item.interval === "year"){
			return `${priceFormatted}/year`;
		}else if(item.interval === "month"){
			return `${priceFormatted}/month`;
		}
		return priceFormatted;
	}
	getItemDescription(item: LineItemModel): string{
		if(item.interval === "year"){
			const renew = new Date();
			renew.setFullYear(new Date().getFullYear() + 1);
			return `Plan will renew on ${formatDatePretty(renew)}`
		}else if(item.interval === "month"){
			const renew = new Date();
			// Stripe's interval_count docs say 3 means "renews every third month"
			// Sets renewal date to Now + 1 month by default, or intervalCount if present
			renew.setMonth(new Date().getMonth() + (item.intervalCount ?? 1));
			return `Plan will renew on ${formatDatePretty(renew)}`
		}
		return item.description;
	}

	$refs: {
		newPaymentMethodForm: PaymentMethodForm;
	}
	newPaymentMethodFormValue: PaymentMethodFormValue = null;
	paymentMethodFormValueChanged(value: PaymentMethodFormValue): void{
		this.newPaymentMethodFormValue = value;
	}

	get ButtonLoading(): boolean{
		return this.updateLineItemsLoading;
	}
	get Loading(): boolean{
		if(!this.CurrentAthleteIsReady) return true;
		return this.checkoutIntentLoading || !this.checkoutIntentInitialized;
	}
	currentStep: number = 1;
	steps: number = 2;
	formValue = {
		step1:{
			valid: true,
			athleteProfiles: {},
			selectedPaymentMethod: null,
			rememberPaymentMethod: false,
		},
		step2:{
			valid: true,
		}
	};
	get SelectedPaymentMethod(): PaymentMethodModel | null{
		if(this.formValue.step1.selectedPaymentMethod === 'new-payment') return null;
		return this.formValue.step1.selectedPaymentMethod;
	}
	get BillingDetailsName(): string{
		if(this.SelectedPaymentMethod !== null) return this.SelectedPaymentMethod.BillingDetailsName;
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.name;
		return "";
	}
	get BillingAddressLine1(): string{
		if(this.SelectedPaymentMethod !== null) return this.SelectedPaymentMethod.BillingAddressLine1;
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.addressLine1;
		return "";
	}
	get BillingDetailsEmail(): string{
		if(this.SelectedPaymentMethod !== null && this.SelectedPaymentMethod.BillingDetailsEmail){
			return this.SelectedPaymentMethod.BillingDetailsEmail;
		}
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.email;
		return this.CurrentUserEmail;
	}
	get BillingAddressPostalCode(): string{
		if(this.SelectedPaymentMethod !== null) return this.SelectedPaymentMethod.BillingAddressPostalCode;
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.postalCode;
		return "";
	}
	get BillingAddressCity(): string{
		if(this.SelectedPaymentMethod !== null) return this.SelectedPaymentMethod.BillingAddressCity;
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.city;
		return "";
	}
	get BillingAddressCountry(): string{
		if(this.SelectedPaymentMethod !== null) return country(this.SelectedPaymentMethod.BillingAddressCountry);
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.country.text;
		return "";
	}
	get BillingAddressState(): string{
		if(this.SelectedPaymentMethod !== null) return this.SelectedPaymentMethod.BillingAddressState;
		if(this.newPaymentMethodFormValue) return this.newPaymentMethodFormValue.province.text;
		return "";
	}
	get AddNewPaymentMethod(): boolean{
		return this.formValue.step1.selectedPaymentMethod === 'new-payment';
	}
	newPaymentMethodError: string | null = null;

	updateAthleteProfiles(athleteProfileId: string, included: boolean): void{
		Vue.set(this.formValue.step1.athleteProfiles, athleteProfileId, included);
		this.debounceUpdateLineItems();
	}
	
	updateLineItemsLoading: boolean = false;
	debounceUpdateLineItems(): void{
		this.updateLineItemsLoading = true;
		this.debounceCallback('updateLineItems', () => this.updateLineItems());
	}
	/**
	 * Computes the line items needed based on the form value.
	 * 
	 * For each unique Product in the checkout's lineitems, we'll add a line item for each athlete.
	 * 
	 * ie. a Verified Assessment for with two athlete profiles selected on the form should result in two VerifiedAssessment LineItems, one for each athlete.
	 */
	async updateLineItems(): Promise<void>{
		const uniqueItems = this.UniqueLineItemsOnly.slice();
		const athleteIds = Object.entries(this.formValue.step1.athleteProfiles).filter(([,include]: [string, boolean]) => include === true).map(([athleteId]) => athleteId);
		const allLineItems = athleteIds.map((athleteId) => {
			return uniqueItems.map(item => new LineItemModel<AthleteLineItemMeta>().load({
				...item,
				meta: {
					athleteId,
				}
			}))
		}).reduce((a,b) => [...a,...b], []);

		// At this point the backend should reject this call if the line items are invalid, and will return a fresh version of the checkout intent with the correct prices
		const updatedCheckoutIntent = await CustomerApi.setCheckoutIntentLineItems('athlete', this.checkoutIntent.parentId, this.checkoutIntent.id, {
			lineItems: allLineItems
		});
		this.setFormCheckoutIntent(updatedCheckoutIntent);
		this.updateLineItemsLoading = false;
	}
	setFormCheckoutIntent(checkoutIntent: CheckoutIntentModel<AthleteLineItemMeta>): void{
		this.checkoutIntent = checkoutIntent;
		const athleteIds = this.checkoutIntent.lineItems
			.filter(item => !!item.meta)
			.map(item => item.meta.athleteId);
		const athleteProfilesMap = athleteIds.reduce((map, athleteId) => {
			map[athleteId] = true;
			return map;
		}, {});
		Vue.set(this.formValue.step1,'athleteProfiles', athleteProfilesMap);

		/**
		 * Ensure Subscription is for the current Athlete Id. This can change if the user swaps profiles during checkout
		 * If the ids don't match, redirect them to the upgrade page to start over.
		 */
		if(this.IsSubscriptionProduct && this.formValue.step1.athleteProfiles[this.CurrentAthleteId] !== true){
			console.warn("Athlete Profile checkout mismatch");
			this.$router.push('/upgrade');
		}
	}
	
	get BillingAddressCols(): Record<string, number>{
		return {
			cols: 12,
			sm: 6,
			md: 4,
			lg: 3,
			xl: 2,
		}
	}

	cancel(): void{
		if(this.currentStep === 2){
			this.currentStep = 1;
		}else{
			this.previous();
		}
	}
	get PaymentIsCompleted(): boolean{
		/** Payment was completed elsewhere, so display completed screen */
		if(this.checkoutAction === "complete" || this.checkoutAction === "cancel") return true;
		if(!this.checkoutIntent) return false;
		if(this.checkoutIntent.status === IntentStatus.succeeded) return true;
		return false;
	}
	async reviewPurchase(): Promise<void>{
		if(this.SelectedPaymentMethod === null){
			// If New Payment Method is selected, manually submit the PaymentMethodForm to retrieve the payment method from Stripe.
			const { paymentMethod, error } = await this.$refs.newPaymentMethodForm.submit();
			if(error) return; // This will be displayed on the form automatically
			this.formValue.step1.selectedPaymentMethod = new PaymentMethodModel().setPaymentMethodDetails(paymentMethod);
		}
		this.next();
	}
	formSubmitting: boolean = false;
	async finish(): Promise<void>{
		this.formSubmitting = true;
		let paymentData: ConfirmCardPaymentData | null = null;
		if(this.SelectedPaymentMethod !== null){
			paymentData = {
				payment_method: this.SelectedPaymentMethod.stripePaymentMethodId,
				save_payment_method: this.formValue.step1.rememberPaymentMethod,
				receipt_email: this.BillingDetailsEmail,
			};
		}
		if(paymentData === null){
			this.currentStep = 1;
			this.formSubmitting = false;
			return;
		}
		const result = await this.checkoutIntent.confirmPurchase({paymentData});
		if(result.error){
			notificationStore.pushErrorNotificationMessage(result.error.message);
		}
		this.formSubmitting = false;
	}

	@Prop({ default: Routes.AthleteProfile }) dashboardRoute: string | null;
	get Dashboard(): string{
		if(this.dashboardRoute !== null) return this.dashboardRoute;
		return Routes.AthleteProfile;
	}
	goToDashboard(): void{
		this.$router.push({ name: this.Dashboard, params: { ...this.$route.params } });
	}
}
