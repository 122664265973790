var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AssessmentDataInfoProvider',{style:(_vm.RootStyle),attrs:{"metric":_vm.metric,"assessmentData":_vm.selfAssessment,"loadData":_vm.loadData},scopedSlots:_vm._u([{key:"default",fn:function({
		athleteId,
		TestDate,
		Title,
		IconComponentName,
		MetricData,
	}){return [(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_c('ExpandingCard',_vm._b({staticClass:"cursor-pointer",attrs:{"height":_vm.height,"headerColor":"baColorAppForeground","headerDark":false,"outlined":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"my-auto pa-4 flex-grow-1"},[_c('span',{staticClass:"upper-case metric-title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(Title)+" ")]})],2)])]},proxy:true},{key:"icon",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(IconComponentName,{tag:"component",attrs:{"color":"baColorVibrantBlue"}})],1)]},proxy:true}],null,true)},'ExpandingCard',_vm.$attrs,false),[_c('div',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"metric-value"},[(MetricData.value)?[_c('span',[_vm._v(_vm._s(MetricData.value))]),_c('span',{staticClass:"metric-unit"},[_vm._v(_vm._s(MetricData.unit))])]:[_vm._v(" -- ")]],2),_c('div',{staticClass:"metric-label"},[_vm._v(" "+_vm._s(MetricData.label)+" ")]),[_vm._t("append-content")]],2)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }