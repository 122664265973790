
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mdiInformationOutline } from '@mdi/js';
import Page from "./Page.vue";
import {
	VuetifyMixin,
	AuthMixin,
	CurrentAthleteMixin,
	MyTeamsMixin,
	MultiStepFormMixin,
} from "../mixins";
import { athleteProfile } from "../store";
import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import MultiStepForm from "@/components/forms/MultiStepForm.vue";
import * as Routes from "@/../types/constants/web_client_user.routes";
import convert from "convert-units";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";

import MultiStepNavItem from "@/components/forms/MultiStepNavItem.vue";
import SettingsNavItem from "@/components/profile/settings/SettingsNavItem.vue";
import EditRecruitingProfileBasic from "@/components/profile/recruiting/EditRecruitingProfileBasic.vue";
import EditRecruitingProfileBio from "@/components/profile/recruiting/EditRecruitingProfileBio.vue";
import EditRecruitingProfileEducation from "@/components/profile/recruiting/EditRecruitingProfileEducation.vue";
import EditRecruitingProfileVideos from "@/components/profile/recruiting/EditRecruitingProfileVideos.vue";
import EditRecruitingProfilePrivacy from "@/components/profile/recruiting/EditRecruitingProfilePrivacy.vue";

@Component({
	components: {
		Page,
		MultiStepForm,
		MultiStepNavItem,
		SettingsNavItem,
		EditRecruitingProfileBasic,
		EditRecruitingProfileBio,
		EditRecruitingProfileEducation,
		EditRecruitingProfileVideos,
		EditRecruitingProfilePrivacy,
		ConfirmationDialog
	}
})
export default class AthleteRecruitingProfileEdit extends Mixins(
	AuthMixin,
	CurrentAthleteMixin,
	VuetifyMixin,
	MyTeamsMixin,
	MultiStepFormMixin,
) {
	mdiInformationOutline = mdiInformationOutline;
	@Prop({ type: String, default: null }) athleteId: string | null;

	created(): void {
		this.initProfile();
	}
	initProfile(): void {
		if (this.CurrentAthleteProfile !== null) {
			this.unsavedAthleteProfile.load(this.CurrentAthleteProfile);
			this.unsavedAthleteProfile.height = this.unsavedAthleteProfile.height
				? parseFloat(
					convert(this.unsavedAthleteProfile.height)
						.from("cm")
						.to(this.unsavedAthleteProfile.heightUnit)
						.toFixed(2)
				)
				: null;
			this.unsavedAthleteProfile.weight = this.unsavedAthleteProfile.weight
				? parseFloat(
					convert(this.unsavedAthleteProfile.weight)
						.from("kg")
						.to(this.unsavedAthleteProfile.weightUnit)
						.toFixed(2)
				)
				: null;
			this.savedAthleteProfile.load(this.CurrentAthleteProfile);
			this.savedAthleteProfile.height = this.unsavedAthleteProfile.height;
			this.savedAthleteProfile.weight = this.unsavedAthleteProfile.weight;
		}
	}

	// unsavedAthleteProfile = this.CurrentAthleteProfile; # not working
	savedAthleteProfile: AthleteProfileModel = new AthleteProfileModel();
	unsavedAthleteProfile: AthleteProfileModel = new AthleteProfileModel();

	navItems = [
		{ text: "Basic Info" },
		{ text: "Bio" },
		{ text: "Education" },
		{ text: "Videos" },
		{ text: "Profile Visibility" }
	];
	steps = 5;
	currentStep = 1;

	saveLoading = false;
	leaveDialogVisible = false;
	confirmAction = (): void => {};
	cancelAction = (): void => {};

	get formBlanks(): number[] {		
		/**
		 * Return the number of empty fields in each form/step
		 */
		// Each each index represents the form and each array contains all the values within that forms
		let stepValues = [
			[
				this.unsavedAthleteProfile.firstName,
				this.unsavedAthleteProfile.lastName,
				this.unsavedAthleteProfile.email,
				this.unsavedAthleteProfile.phone,
				this.unsavedAthleteProfile.primaryPosition,
				this.unsavedAthleteProfile.secondaryPosition,
				this.unsavedAthleteProfile.height,
				this.unsavedAthleteProfile.weight,
				this.unsavedAthleteProfile.dominantSide,
				this.unsavedAthleteProfile.pictureUrl,
				this.unsavedAthleteProfile.gender,
			],
			[
				this.unsavedAthleteProfile.shortBio,
				this.unsavedAthleteProfile.highestLevelPlayed,
				this.unsavedAthleteProfile.currentLevel,
				this.unsavedAthleteProfile.previousClubs,
				this.unsavedAthleteProfile.languagesSpoken,
				this.unsavedAthleteProfile.citizenships,
				this.unsavedAthleteProfile.socialMedia
			],
			[
				this.unsavedAthleteProfile.highschoolGPA,
				this.unsavedAthleteProfile.highschoolName,
				this.unsavedAthleteProfile.highschoolGradYear,
				this.unsavedAthleteProfile.SATScore,
				this.unsavedAthleteProfile.anticipatedStudy
			],
			[],
			[]
		];

		// Variable represents the number of empty fields in each form
		var formBlanks = [];
		stepValues.forEach(values => {
			var blanks = 0;
			values.forEach(value => {
				blanks += !value || (Array.isArray(value) && !value.length) ? 1 : 0;
			});
			formBlanks.push(blanks);
		});

		return formBlanks;
	}

	get ShouldGoToProfileView(): boolean {
		return this.savedAthleteProfile.recruitingProfileComplete || (this.currentStep + 1 >= this.navItems.length && !this.savedAthleteProfile.recruitingProfileComplete)
	}
	async saveClicked(controls: { next: () => void, previous: () => void, cancel: () => void, finish: () => void }, validate: () => Promise<boolean>): Promise<void> {
		if (await validate()) {
			this.saveLoading = true;
			// Save the current step
			await this.saveProfile();
			this.saveLoading = false;

			// Go to the viewing page if recruiting profile is complete or on the last page when creating a new recruiting profile
			if (this.ShouldGoToProfileView) {
				setTimeout(() => {
					this.navigateToRecuritingProfile();
				}, 161)
			}
			else {
				controls.next()
			}
		}
	}

	async navigateToRecuritingProfile(): Promise<void> {
		/**
		 * Navigates to the recruiting profile viewing page
		 */
		this.$router.push({
			name: Routes.AthleteRecruitingProfileView,
			params: {
				...this.$route.params
			}
		});
	}

	async saveProfile(): Promise<void> {
		// Convert from the user's unit of preference to metric
		let height = this.unsavedAthleteProfile.height
			? convert(this.unsavedAthleteProfile.height)
				.from(this.unsavedAthleteProfile.heightUnit)
				.to("cm")
			: null;
		let mass = this.unsavedAthleteProfile.weight
			? convert(this.unsavedAthleteProfile.weight)
				.from(this.unsavedAthleteProfile.weightUnit)
				.to("kg")
			: null;

		switch (this.currentStep) {
		case 1:
			await athleteProfile.patchAthleteProfile({
				patch: {
					id: this.athleteId,
					firstName: this.unsavedAthleteProfile.firstName,
					lastName: this.unsavedAthleteProfile.lastName,
					email: this.unsavedAthleteProfile.email,
					dominantSide: this.unsavedAthleteProfile.dominantSide,
					phone: this.unsavedAthleteProfile.phone,
					primaryPosition: this.unsavedAthleteProfile.primaryPosition,
					secondaryPosition: this.unsavedAthleteProfile.secondaryPosition,
					pictureUrl: this.unsavedAthleteProfile.pictureUrl,
					gender: this.unsavedAthleteProfile.gender,
					height: height,
					heightUnit: this.unsavedAthleteProfile.heightUnit,
					weight: mass,
					weightUnit: this.unsavedAthleteProfile.weightUnit,
				}
			});
			this.savedAthleteProfile.load(this.unsavedAthleteProfile);
			break;
		case 2:
			await athleteProfile.patchAthleteProfile({
				patch: {
					id: this.athleteId,
					shortBio: this.unsavedAthleteProfile.shortBio,
					highestLevelPlayed: this.unsavedAthleteProfile.highestLevelPlayed,
					currentLevel: this.unsavedAthleteProfile.currentLevel,
					previousClubs: this.unsavedAthleteProfile.previousClubs,
					languagesSpoken: this.unsavedAthleteProfile.languagesSpoken,
					citizenships: this.unsavedAthleteProfile.citizenships,
					socialMedia: this.unsavedAthleteProfile.socialMedia
				},
			});
			this.savedAthleteProfile.load(this.unsavedAthleteProfile);
			break;
		case 3:
			await athleteProfile.patchAthleteProfile({
				patch: {
					id: this.athleteId,
					highschoolGPA: this.unsavedAthleteProfile.highschoolGPA,
					highschoolName: this.unsavedAthleteProfile.highschoolName,
					highschoolGradYear: this.unsavedAthleteProfile.highschoolGradYear ? this.unsavedAthleteProfile.highschoolGradYear : null,
					SATScore: this.unsavedAthleteProfile.SATScore,
					anticipatedStudy: this.unsavedAthleteProfile.anticipatedStudy
				}
			});
			this.savedAthleteProfile.load(this.unsavedAthleteProfile);
			break;
		case 4:
			await athleteProfile.patchAthleteProfile({
				patch: {
					id: this.athleteId,
					profileVisibility: this.unsavedAthleteProfile.profileVisibility,
					recruitingProfileComplete: true
				},
			});
			this.savedAthleteProfile.load(this.unsavedAthleteProfile);
			break;
		default:
			break;
		}
	}

	get Loading(): boolean {
		return !athleteProfile.AthleteProfileIsReady;
	}

	async navItemClick(reset:VoidFunction, itemNumber:number = 0, invalid: boolean = false, changed: boolean = false, dirty: boolean = false): Promise<void> {
		/**
		 * Pops up a confirmation dialog if the data has been changed, otherwise continue with the nav item changed;
		 * Form state (changed, pristine, etc.) is reset on save
		 */
		if (changed) {
			this.confirmAction = async() => {
				await this.saveProfile()
				this.leaveDialogVisible = false;
				this.currentStep = itemNumber + 1;
				this.savedAthleteProfile.load(this.unsavedAthleteProfile);
				reset()
			}
			this.cancelAction = async () => {
				this.leaveDialogVisible = false;
				this.currentStep = itemNumber + 1;
				this.unsavedAthleteProfile.load(this.savedAthleteProfile);
			}
			this.leaveDialogVisible = true;
		}
		else 
			this.currentStep = itemNumber + 1;
		
	}

	async accessControlClick(invalid: boolean = false, changed: boolean = false, dirty: boolean = false): Promise<void> {
		/**
		 * If the form has been changed will popup dialog to save or discard changes
		 */
		if (changed) {
			this.confirmAction = async() => {
				await this.saveProfile()
				this.$router.push({
					name: Routes.AthleteSettingsAccessControlForm,
					params: {
						...this.$route.params
					}
				});
				this.leaveDialogVisible = false;
			}
			this.cancelAction = () => {
				this.$router.push({
					name: Routes.AthleteSettingsAccessControlForm,
					params: {
						...this.$route.params
					}
				})
				this.leaveDialogVisible = false
			}
			this.leaveDialogVisible = true;
		}
		else 
			this.$router.push({
				name: Routes.AthleteSettingsAccessControlForm,
				params: {
					...this.$route.params
				}
			});
		
	}
}
