import { Country } from "@/../types/enums";
import { ESSchoolModel } from "@/models/school/SchoolModel";
import { RepositoryQuery } from "@/../types/interfaces";

export const DivisionFilters = [
	{ text: "NCAA I", value: "NCAA I", disabled: false },
	{ text: "NCAA II", value: "NCAA II", disabled: false },
	{ text: "NCAA III", value: "NCAA III", disabled: false },
	{ text: "NAIA", value: "NAIA", disabled: false },
	{ text: "NJCAA I", value: "NJCAA I", disabled: false },
	{ text: "NJCAA II", value: "NJCAA II", disabled: false },
	{ text: "NJCAA III", value: "NJCAA III", disabled: false },
	{ text: "CCCAA/COA", value: "CCCAA/COA", disabled: false },
	{ text: "NWAC", value: "NWAC", disabled: false },
	{ text: "IND 2YR", value: "Ind 2yr", disabled: false },
	{ text: "IND 4YR", value: "Ind 4yr", disabled: false },
	{ text: "USCAA", value: "USCAA", disabled: false },
	{ text: "U SPORTS", value: "U SPORTS", disabled: false },
	{ text: "ACCA", value: "ACCA", disabled: false },
	{ text: "Other", value: "Other", disabled: true },
];
export interface FilterValue {
	text: string,
	value: RepositoryQuery<ESSchoolModel>
}
export const RegionsFilters = [
	{ text: "West", value: { 
		text: "West",
		value: {
			$and: [
				{ "locations.state": { $any: ["WA", "OR", "NV", "CA", "HI", "AK"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Rocky Mountain", value: { 
		text: "Rocky Mountain",
		value: {
			$and: [
				{ "locations.state": { $any: ["MT", "WY", "ID", "UT", "CO"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Southwest", value: { 
		text: "Southwest",
		value: {
			$and: [
				{ "locations.state": { $any: ["AZ", "NM", "OK", "TX"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Plains", value: { 
		text: "Plains",
		value: {
			$and: [
				{ "locations.state": { $any: ["ND", "SD", "NE", "KS", "MO", "IA", "MN"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Great Lakes", value: { 
		text: "Great Lakes",
		value: {
			$and: [
				{ "locations.state": { $any: ["WI", "MI", "IL", "IN", "OH"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Southeast", value: { 
		text: "Southeast",
		value: {
			$and: [
				{ "locations.state": { $any: ["WV", "VA", "KY", "TN", "NC", "AR", "LA", "MS", "AL", "GA", "SC", "FL"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Mideast", value: { 
		text: "Mideast",
		value: {
			$and: [
				{ "locations.state": { $any: ["NY", "PA", "NJ", "DE", "DC", "MD"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "New England", value: { 
		text: "New England",
		value: {
			$and: [
				{ "locations.state": { $any: ["ME", "NH", "MA", "RI", "CT", "VT"]}  },
				{ "locations.country": Country.US },
			]}
	}, disabled: false },
	{ text: "Canada West Universities Athletic Association", value: { 
		text: "Canada West Universities Athletic Association",
		value: {
			$and: [
				{ "locations.state": { $any: ['BC', 'AB', 'SK', 'MB']}  },
				{ "locations.country": Country.CA },
			]}
	}, disabled: false },
	{ text: "Ontario University Athletics", value: { 
		text: "Ontario University Athletics",
		value: {
			$and: [
				{ "locations.state": { $any: ['ON']}  },
				{ "locations.country": Country.CA },
			]}
	}, disabled: false },
	{ text: "Canada RESQ", value: { 
		text: "Canada RESQ",
		value: {
			$and: [
				{ "locations.state": { $any: ['QC']}  },
				{ "locations.country": Country.CA },
			]}
	}, disabled: false },
	{ text: "Atlantic University Sport", value: { 
		text: "Atlantic University Sport",
		value: {
			$and: [
				{ "locations.state": { $any: ['NS', 'NL', 'NB', 'PEI']}  },
				{ "locations.country": Country.CA },
			]}
	}, disabled: false },
];

export const AcademicSelectivityFilters = [
	{ text: "Most Selective", value: [0, .15], disabled: false },
	{ text: "More Selective", value: [.151, .35], disabled: false },
	{ text: "Selective", value: [.351, .5], disabled: false },
	{ text: "Less Selective", value: [.501, .75], disabled: false },
	{ text: "Least Selective", value: [.751, 1], disabled: false },
]

export const UndergraduateEnrollmentFilters = [
	{ text: "Less than 3,000", value: [0, 3000], disabled: false },
	{ text: "3,000 to 10,000", value: [3000.1, 10000], disabled: false },
	{ text: "10,000 to 20,000", value: [10000.1, 20000], disabled: false },
	{ text: "More than 20,000", value: [20000.1, 100000], disabled: false },
]