import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export class RegisterForAssessmentMixin extends Vue {
	registerForAssessment(): void{
		this.$router.push(this.RegisterForAssessmentRoute);
	}
	get RegisterForAssessmentRoute(): Partial<Route>{
		return {
			path: '/event/register',
		}
	}
}
