<template>
	<v-img v-bind="$attrs" contain :src="imgCard">
		<slot></slot>
	</v-img>
</template>
<script>
import imgCard from '@/assets/img/payment-card-check.svg'
export default{
	data(){
		return { imgCard }
	}
}
</script>